@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;


.b-404-page {
    text-align: center;
    padding: 0 rem(25);
}

.b-404-page__inner {
    max-width: rem(800);
    width: 100%;
    margin: 0 auto;
    padding: rem(80) 0 rem(150);
}
.b-404-page__title {
    font-size: rem(40);
    font-weight: 600;
    margin-bottom: rem(20);
    color: $base;
}
.b-404-page__sub-title {
    font-size: rem(20);
    font-weight: 500;
    margin-bottom: rem(40);
}
.b-404-page__text {
    font-size: rem(16);
}

@media screen and (max-width: 767px) {
    .b-404-page__inner {
        max-width: rem(600);
        padding: rem(80) 0 rem(80);
    }
    .b-404-page__title {
        font-size: rem(24);
    }
    .b-404-page__sub-title {
        font-size: rem(16);
    }
    .b-404-page__text {
        font-size: rem(14);
    }
}