@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-work {
  margin-top: 100px;
}
.b-work__wrap {
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 20px;
position: relative;
padding: 0 60px;
max-width: rem(1300);
width: 100%;
margin: 0px auto;
}
.b-work__inner {
  position: relative;
}
.b-work__inner::before {
position: absolute;
content: '';
background-color: #95DBDC;
border-radius: 130px 0 0 130px;
width: 95%;
height: 85%;
z-index: -10;
right: 0;
bottom: 30px;
}
//カードの幅や配置指定
.b-work__box {
display: flex;
flex-direction: column;
height: auto;
width: 100%;
max-width: calc(33.333% - rem(80) * 2 / 3);
position: relative;
background-color: #fff;
padding: 20px 17px;
border-radius: 15px;
box-shadow: 0px 0px 3px rgba( $base, 0.2);
}
//カードの中身
.b-work__box__day {
  font-size: rem(12);
}
.b-work__box__text-box{
  margin: 10px 0px 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.b-work__box__title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: rem(14);
  display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.b-work__box__img {
  text-align: center;
  padding-top: 66.666%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.b-work__box__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease;
}
.b-work__box__label {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: fit-content;
  font-size: rem(12);
  font-weight: 700;
  color: #fff;
  padding: 0px 20px;
  background-color: $base;
  border-radius: 15px;
  z-index: 1;

  &--spfirst {
    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background-image: url('../images/common/icon-sp.svg');
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}

//hover時のアニメーション
.b-work__box:hover {
  .b-work__box__img img{
    transform: translate(-50%, -50%) scale(1.2);
  }
  .b-btn-arrow.b-btn-arrow--white {
    .b-btn-arrow__icon {
      background: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
      border: solid 2px $white;
  }
  .b-btn-arrow__icon::before {
      background: url(../images/common/arrow-white.png) no-repeat center center / contain;
  }
  }
}

.b-work__box__text-box {
  .b-btn-arrow.b-btn-arrow--white {
    margin-top: auto;
  }

  .b-btn-arrow {
    margin-top: auto;
  }
}

.b-work__box__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
  height: 36px;
  overflow: hidden;
  z-index: 3;

  &__item {
    a {
      display: flex;
      align-items: center;
      height: 36px;
      width: fit-content;
      padding: 0px 10px;
      font-size: rem(12);
      font-weight: 700;
      color: $base;
      background-color: #fff;
      border: 1px solid $base;
      border-radius: 18px;
    }
  }
}


// タグ

.b-work__box__tag {
  position: absolute;
  display: inline-block;
  background-color: $base;
  border-radius: 30px;
  color:#ffffff;
  font-size: rem(12);
  font-weight: bold;
  padding: 6px 16px;
  top:10%;
  left: 5%;
  z-index: 1000;

  a {
    position: relative;
    z-index: 1000;
  }
}

//link
.b-work__box__link {
  position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
    box-sizing: border-box;
    transition: 0.3s ease;
}

// 背景のリング飾り
.b-work__ring {
  position: relative;
}
.b-work__ring__img {
  position: absolute;
  width: 280px;
  left: 10%;
  animation: rotate-anime 20s linear infinite;
}

@keyframes rotate-anime {
  0%  {transform: rotate(0);}
  100%  {transform: rotate(360deg);}
}

.b-work__ring__img2 {
  position: absolute;
  width: 260px;
  right: 10%;
  top: 520px;
  animation: rotate-anime 15s linear infinite;
}

@media screen and (max-width: 1024px) {
  .b-work__wrap {
    padding: 30px 20px;
    max-width: rem(800);
    width: 100%;
  }
  .b-work__box {
    width: 48%;
  }
  //カードの幅や配置指定
.b-work__box {
  max-width: calc(50% - rem(42) * 1 / 2);
  width: 100%;
  }

  //topのお知らせのみ切り替えしを変える
.b-news__inner {
  .b-work__box {
    max-width: calc(33.333% - rem(42) * 2 / 3);
    width: 100%;
    position: relative;
    }
}



.b-work__inner::before{
  border-radius: 0 0 0 130px;
  width: 100%;
}
.b-work__ring__img{
  width: 140px;
}

.b-work__box__tag {
  padding: 5px 10px;
}
.b-work__box {
  width: 100%;
}
.b-work__box__text-box {
  margin-bottom: 16px;

  .b-btn-arrow {
    margin-top: auto;
  }
}

.b-work__ring__img  {
  display: none;
}
.b-work__ring__img2 {
  display: none;
}
.b-work__box__img img {
  border-radius: 10px;
}
}
@media screen and (max-width: 600px) {
  .b-work__inner::before {
    height: 95%;
    }
  .b-work__wrap {
    max-width: rem(500);
    width: 100%;
  }
  .b-work__box {
    width: 100%;
    max-width: 100%;
  }

}
 //topのお知らせのみ切り替えしを変える
@media screen and (max-width : 767px) {
  .b-news__inner {
    .b-work__wrap {
      max-width: rem(450);
      width: 100%;
    }
    .b-work__box {
      max-width: 100%;
      }
      .b-work__box:not(:first-child) {
        margin-left: 0;
        margin-top: rem(32);
      }
      .b-work__box:nth-child(3n + 1) {
        margin-left: 0;
      }
      .b-work__box:nth-child(n + 3) {
        margin-top: rem(32);
      }
  }
}

//未投稿の場合
.b-work__wrap__text {
  text-align: center;
  font-size: rem(16);
  font-weight: bold;
  margin: 0 auto;
  margin-top: 100px;
  color: black;
}

