/* ------------------------------ */
/* FV Contents */
/* ------------------------------ */
@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-fv__contents {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84%;
  height: 512px;

  &__title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    z-index: 1;
  
    &__main {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 350px;
      height: 80px;
      font-size: rem(30);
      font-weight: 700;
      letter-spacing: 0.1em;
      color: $base;
      background-image: url('../images/common/title-bg.png');
      background-size: 100%;
      background-repeat: no-repeat;
    }
  
    &__sub {
      font-size: rem(15);
      color: $base;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    z-index: 0;
  }

  @media screen and (max-width:768px) {
    width: 90%;

    &__title {
      &__main {
        width: 250px;
        height: 60px;
        font-size: rem(24);
      }
  
      &__sub {
        font-size: rem(12);
      }
    }
  
    &__bg {
      border-top-left-radius: 0px;
    }
  }
}