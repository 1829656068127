@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-pagination {
  text-align: center;
  margin: 64px auto 0;
  max-width: 1300px;
}

.pagination {/* 全体 */
  margin: 20px 0;
  font-size: 12px;
  font-weight: 700;
}
.page-numbers {/* ボタン */
  margin: 0 6px 0 0;
  display: inline-block;
  border: solid 1px #eee;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  transition: all .4s ease;
}
.page-numbers.current,
a.page-numbers:hover {/* カレント数字 */
  border: none;
  background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
  color: #fff;
  border: solid 1px #eee;
  opacity: 1;
}
a.page-numbers {/* マウスオーバー */
  background: #fff;
  color: $base;
  border-radius: 50px;
}
