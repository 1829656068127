@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;


.b-other__box {
display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 0;
  max-width: rem(1100);
  width: 100%;
  margin: auto;
}

.b-other__box__image {
  flex: 0 1 44%;
  margin-left: 4%;
  img {
    border-radius: 60px;
    width: 100%;
  }
}

.b-other__box__text {
  flex: 0 0 52%;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .b-other__box {
    flex-wrap: wrap-reverse;
    padding-top: 50px;
    max-width: rem(600);
    width: 100%;
    margin: auto;
  }
  .b-other__box__image {
    margin-right: 0;
    width: 100%;
    flex: auto;
    margin-top: 20px;
  }
  .b-other__box__text {
    width: 100%;
    flex: auto;
  }

}

 