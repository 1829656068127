@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-header-second  {
background-color: $sub;
padding-top: 20px;
display: flex;
justify-content: space-between;
align-items: center;
margin: 0 20px;
border-radius: 50px;
height: 90px;
background-color: #fff;
padding: 0 rem(20);
position: fixed;
top: -100px;
z-index: 9999;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.visible{ 
  margin: auto;
  width: 100%;
  top: 20px;
  transition: .5s; /* アニメーションタイミング */
}


.b-header-second__logo{
  width: 60px;
  height: 68px;
  margin-left: rem(24);
}
.b-header-second__logo a {
width: 100%;
height: 100%;
display: flex;
align-items: center;
font-size: rem(15);
}

@media screen and (max-width: 767px) {
.b-header-second {
  display: none;
}
}
