@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-title-box-w{
  color: $base;
  margin: 0 auto;
  position: relative;
}
.b-title-box-w__title {
  font-size:rem(36);
  position:absolute;
  top: 135px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: $base;
}
.b-title-box-w__bg {
  width: 87px;
  position:absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  top: 120px;
}
.b-title-box-w__subtitle {
  position:absolute;
  top: 165px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: $base;
}