@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

 /*スクロールダウン全体の場所*/
.b-scrolldown {
  /*描画位置※位置は適宜調整してください*/
  position: relative;
  bottom: -10%;
  /*全体の高さ*/
  height: 50px;
  margin-top: 50px;
}

/*Scrollテキストの描写*/
.b-scrolldown span {
  /*描画位置*/
  position: absolute;
  left: -6px;
  bottom: 53px;
  /*テキストの形状*/
  color: $base;
  font-size: 15px;
  letter-spacing: 0.05em;
  /*縦書き設定*/
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}

/* 線の描写 */
.b-scrolldown::before {
  content: "";
  /*描画位置*/
  position: absolute;
  bottom: 0;
  left: 2px;
  /*丸の形状*/
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $base;
  /*丸の動き1.6秒かけて透過し、永遠にループ*/
  animation: circlemove 1.8s ease-in-out infinite,
    cirlemovehide 1.8s ease-out infinite;
}

/*下からの距離が変化して丸の全体が上から下に動く*/
@keyframes circlemove {
  0% {
    bottom: 25px;
  }
  100% {
    bottom: -53px;
  }
}

/* 線の描写 */
.b-scrolldown:after {
  content: "";
  /*描画位置*/
  position: absolute;
  bottom: -50PX;
  left: 5PX;
  /*線の形状*/
  width: 3px;
  height: 75px;
  background: $base;
}


@media screen and (max-width: 767px) {
  .b-scrolldown {
    /*描画位置※位置は適宜調整してください*/
    position: absolute;
    bottom: 12%;
    left: 3%;
    margin-top: 0;
  }
}