@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

li {
  list-style-type: none; /*リストマーカー無しにする*/
}

body {
  font-family: "Noto Sans JP", sans-serif;
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}

/* リキッドレイアウト対応 */
main {
  overflow: hidden;
}

html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: vw(375, 16);
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: vw(strip-unit(1100px), 16);
  }
}
@media (min-width: 1100px) {
  html {
    font-size: 16px;
  }
}

/* pcの電話番号発信対応 */
@media screen and (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  a:hover {
    opacity: 0.8;
  }
}

main {
  color: #333;
  font-family: "Noto Sans JP", sans-serif;
}

.b-about {
  background-color: rgb(234, 244, 244);
  padding-top: 40px;
  border-radius: 0 0 0 130px;
  position: relative;
}
.b-about__inner {
  height: 550px;
}
.b-about__text-box {
  position: relative;
  z-index: 10;
}
.b-about__img {
  z-index: 10;
}

.b-about__img {
  position: relative;
}

.b-about__img::before {
  position: absolute;
  content: "";
  width: 132px; /*横幅*/
  height: 152px; /*縦幅*/
  background: rgb(251, 247, 199); /*背景色*/
  top: -100px;
  right: -10px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}

.b-about__img::after {
  position: absolute;
  content: "";
  width: 281px; /*横幅*/
  height: 313px; /*縦幅*/
  background: #95DBDC; /*背景色*/
  bottom: -180px;
  right: -10px;
  animation: fluidrotate2 20s ease 0s infinite; /*アニメーションの設定*/
  z-index: -10;
}

@keyframes fluidrotate {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes fluidrotate2 {
  0%, 100% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  42% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  56% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  70% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  84% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
}
.b-about__text-box::after {
  content: "";
  width: 513px;
  background: url(../images/common/Group.png) no-repeat center center;
  background-size: contain;
  height: 420px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -10;
}

@media screen and (max-width: 1024px) {
  .b-about {
    height: 800px;
    border-radius: 0 0 130px 0;
  }
  .b-about__img::before {
    width: 80px; /*横幅*/
    height: 100px; /*縦幅*/
    top: -30px;
  }
  .b-about__img::after {
    width: 150px; /*横幅*/
    height: 130px; /*縦幅*/
    left: 10px;
    bottom: -90px;
  }
  .b-about__text-box {
    margin-bottom: 50px;
  }
  .b-about__text-box::after {
    width: 100%;
  }
}
.b-fv {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 90px);
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 50%, rgb(234, 244, 244) 50%, rgb(234, 244, 244) 100%);
}
.b-fv--page {
  height: 512px;
  background: none;
}

.b-fv__movie-wrap {
  width: 84%;
  height: 100%;
  position: relative;
}

.b-fv__movie {
  border-top-left-radius: 130px;
  border-bottom-left-radius: 130px;
  position: relative;
  object-fit: cover;
  height: 100%;
  width: 100%;
  filter: brightness(70%);
}

.b-fv__title-wrap {
  position: absolute;
  left: 4%;
  bottom: 10%;
}

.b-fv__title {
  font-family: "Kiwi Maru", serif;
  color: #2AB7B8;
  font-size: 3rem;
  background-color: #fff;
  padding: 8px 40px;
  border-radius: 30px;
  display: block;
  width: fit-content;
}
.b-fv__title--second {
  margin: 1rem auto 0;
}

.b-fv__movie::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.b-fv__side-bar {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin: 0 auto;
  height: 100%;
}

.b-fv__logo {
  display: block;
  width: 6.875rem;
}
.b-fv__logo img {
  width: 100%;
}

.b-fv__icon {
  display: block;
  width: 3.75rem;
  left: 27%;
}
.b-fv__icon img {
  width: 100%;
}

.b-fv__kv {
  position: relative;
  width: 84%;
  height: calc(100vh - 140px);
  padding: 0px 0px 8px;
}
.b-fv__kv__title {
  position: absolute;
  left: 4%;
  bottom: 10%;
}
.b-fv__kv__title__txt {
  font-family: "Kiwi Maru", serif;
  color: #2AB7B8;
  font-size: 3rem;
  background-color: #fff;
  padding: 8px 40px;
  border-radius: 30px;
  display: block;
  width: fit-content;
}
.b-fv__kv__title__txt--second {
  margin: 1rem auto 0;
}
.b-fv__kv__img {
  display: block;
  height: 100%;
  border-top-left-radius: 130px;
  border-bottom-left-radius: 130px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.b-fv__kv__img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .b-fv {
    height: calc(100vh - 80px);
  }
  .b-fv--page {
    height: 512px;
  }
  .b-fv__sns {
    display: none;
  }
  .b-fv__movie-wrap {
    width: 90%;
    height: 100%;
  }
  .b-fv__movie {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 90px;
  }
  .b-fv__logo {
    width: 3.75rem;
    margin-top: -49px;
    margin-left: 20px;
  }
  .b-fv__title {
    font-size: 1.75rem;
    font-size: 7.4666666667vw;
    padding: 4px 20px;
  }
  .b-fv__title--first {
    top: 46%;
  }
  .b-fv__title--second {
    top: 58%;
  }
  .b-fv__kv {
    width: 90%;
  }
  .b-fv__kv__title__txt {
    font-size: 1.75rem;
    font-size: 7.4666666667vw;
    padding: 4px 20px;
  }
  .b-fv__kv__title__txt--first {
    top: 46%;
  }
  .b-fv__kv__title__txt--second {
    top: 58%;
  }
  .b-fv__kv__img {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 90px;
  }
}
/* ------------------------------ */
/* FV Contents */
/* ------------------------------ */
.b-fv__contents {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84%;
  height: 512px;
}
.b-fv__contents__title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  z-index: 1;
}
.b-fv__contents__title__main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 80px;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #2AB7B8;
  background-image: url("../images/common/title-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.b-fv__contents__title__sub {
  font-size: 0.9375rem;
  color: #2AB7B8;
}
.b-fv__contents__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  .b-fv__contents {
    width: 90%;
  }
  .b-fv__contents__title__main {
    width: 250px;
    height: 60px;
    font-size: 1.5rem;
  }
  .b-fv__contents__title__sub {
    font-size: 0.75rem;
  }
  .b-fv__contents__bg {
    border-top-left-radius: 0px;
  }
}

.b-header {
  height: 90px;
  line-height: 90px;
}

.b-header__nav__inner {
  display: flex;
  justify-content: flex-end;
  font-size: 0.9375rem;
  height: 90px;
  align-items: center;
  font-weight: bold;
}

.b-header__link {
  margin-right: 32px;
  height: inherit;
}

.b-header__link a {
  display: block;
  height: inherit;
  line-height: 90px;
}

.b-header-sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .b-header {
    display: none;
  }
  .b-header-sp {
    height: 80px;
    line-height: 90px;
    background: #ffffff;
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
  }
  .b-fv__logo__img {
    width: 60px;
  }
}
.menu-btn {
  position: relative;
  top: 10px;
  right: 10px;
  display: flex;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: #2AB7B8;
  border-radius: 10px;
  margin-left: auto;
}

.menu-btn span,
.menu-btn span:before,
.menu-btn span:after {
  content: "";
  display: block;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background-color: #ffffff;
  position: absolute;
}

.menu-btn span:before {
  bottom: 8px;
}

.menu-btn span:after {
  top: 8px;
}

#menu-btn-check:checked ~ .menu-btn span {
  background-color: rgba(255, 255, 255, 0); /*メニューオープン時は真ん中の線を透明にする*/
}

#menu-btn-check:checked ~ .menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}

#menu-btn-check:checked ~ .menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}

.menu-content__inner {
  padding: 80px 10px;
}

.menu-content__list {
  border-bottom: solid 1px #ffffff;
  list-style: none;
}

.menu-content__list a {
  display: block;
  width: 100%;
  font-size: 15px;
  box-sizing: border-box;
  color: #ffffff;
  text-decoration: none;
  padding: 9px 15px 10px 0;
  position: relative;
  font-weight: bold;
}

.menu-content {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 100%; /*leftの値を変更してメニューを画面外へ*/
  z-index: 80;
  background-color: #2AB7B8;
  transition: all 0.5s; /*アニメーション設定*/
}

#menu-btn-check:checked ~ .menu-content {
  left: 0; /*メニューを画面内へ*/
}

/*スクロールダウン全体の場所*/
.b-scrolldown {
  /*描画位置※位置は適宜調整してください*/
  position: relative;
  bottom: -10%;
  /*全体の高さ*/
  height: 50px;
  margin-top: 50px;
}

/*Scrollテキストの描写*/
.b-scrolldown span {
  /*描画位置*/
  position: absolute;
  left: -6px;
  bottom: 53px;
  /*テキストの形状*/
  color: #2AB7B8;
  font-size: 15px;
  letter-spacing: 0.05em;
  /*縦書き設定*/
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}

/* 線の描写 */
.b-scrolldown::before {
  content: "";
  /*描画位置*/
  position: absolute;
  bottom: 0;
  left: 2px;
  /*丸の形状*/
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2AB7B8;
  /*丸の動き1.6秒かけて透過し、永遠にループ*/
  animation: circlemove 1.8s ease-in-out infinite, cirlemovehide 1.8s ease-out infinite;
}

/*下からの距離が変化して丸の全体が上から下に動く*/
@keyframes circlemove {
  0% {
    bottom: 25px;
  }
  100% {
    bottom: -53px;
  }
}
/* 線の描写 */
.b-scrolldown:after {
  content: "";
  /*描画位置*/
  position: absolute;
  bottom: -50PX;
  left: 5PX;
  /*線の形状*/
  width: 3px;
  height: 75px;
  background: #2AB7B8;
}

@media screen and (max-width: 767px) {
  .b-scrolldown {
    /*描画位置※位置は適宜調整してください*/
    position: absolute;
    bottom: 12%;
    left: 3%;
    margin-top: 0;
  }
}
.b-section__inner {
  display: flex;
  max-width: 68.75rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
}
.b-section__inner--column {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1004px;
}
.b-section__title-box {
  margin-bottom: 24px;
  color: #2AB7B8;
}
.b-section__text-box {
  margin-right: 20px;
  z-index: 10;
  color: #2AB7B8;
  width: 70%;
  line-height: 1.75;
}
.b-section__img img {
  border-radius: 50px;
}
.b-section__title-box__title {
  font-size: 2.25rem;
  color: #2AB7B8;
  display: block;
}
.b-section__title-box__subtitle {
  margin-top: 16px;
}
.b-section__text-box-text {
  margin-top: 24px;
  color: #333333;
}
.b-section__title {
  font-size: 2.25rem;
  font-weight: 700;
  color: #2AB7B8;
  margin: 0px 0px 20px;
  word-break: break-all;
}
.b-section__title::after {
  content: "";
  display: block;
  width: 36px;
  height: 2px;
  margin: 5px 0px 0px;
  background-color: #2AB7B8;
}
.b-section__title-with-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto 40px;
}
.b-section__title-with-icon__main {
  font-size: 2.25rem;
  font-weight: 700;
  color: #2AB7B8;
  letter-spacing: 0.1em;
}
.b-section__title-with-icon__sub {
  font-size: 0.875rem;
  color: #2AB7B8;
}
.b-section__title-with-icon::before {
  content: "";
  display: block;
  width: 48px;
  height: 40px;
  background-image: url("../images/common/icon-linkids.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.b-section__lead {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.8;
  margin: 0px 0px 30px;
}
.b-section__txt {
  font-size: 1rem;
  line-height: 1.8;
}
.b-section--bg-blue, .b-section--bg-light-blue {
  position: relative;
  padding: 100px 0px;
}
.b-section--bg-blue::before, .b-section--bg-light-blue::before {
  content: "";
  position: absolute;
  top: 0px;
  display: block;
  height: 100%;
  width: calc(100% - 64px);
  z-index: -1;
}
.b-section--bg-blue::before {
  right: 0px;
  background-color: #95DBDC;
  border-top-left-radius: 130px;
  border-bottom-left-radius: 130px;
}
.b-section--bg-blue .b-section__title {
  color: #fff;
}
.b-section--bg-blue .b-section__title::after {
  background-color: #fff;
}
.b-section--bg-light-blue::before {
  left: 0px;
  background-color: rgb(234, 244, 244);
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
}
.b-section--with-watermark {
  position: relative;
}
.b-section--with-watermark::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 100px;
  width: 502px;
  height: 400px;
  background-image: url("../images/common/watermark-logo.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}
@media screen and (max-width: 1024px) {
  .b-section--bg-light-blue, .b-section--bg-blue {
    padding: 60px 0px;
  }
  .b-section--bg-light-blue::before, .b-section--bg-blue::before {
    width: 100%;
    border-radius: 0px;
  }
  .b-section--with-watermark::after {
    right: initial;
    left: 50%;
    width: 300px;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 768px) {
  .b-section__title {
    font-size: 1.875rem;
  }
  .b-section__title-with-icon {
    margin: 0px auto 24px;
  }
  .b-section__title-with-icon__main {
    font-size: 1.625rem;
  }
  .b-section__title-with-icon__sub {
    font-size: 1rem;
  }
  .b-section__title-with-icon::before {
    width: 48px;
    height: 48px;
  }
  .b-section__lead {
    font-size: 1.125rem;
  }
}

.b-section__title-box__title {
  position: relative;
  width: 100%;
  height: 60px;
}

.b-section__title-box__title::after {
  content: "";
  width: 56px;
  height: 3px;
  position: absolute;
  bottom: -8px;
  left: 30px;
  transform: translateY(-50%) translateX(-50%);
  background-color: #2AB7B8;
}

.b-section__title-box__title__1 {
  animation: bound-anim 0.8s 0s infinite;
  position: absolute;
}

.b-section__title-box__title__2 {
  left: 1.4em;
  animation: bound-anim 0.8s 0.1s infinite;
  position: absolute;
}

.b-section__title-box__title__3 {
  left: 2.8em;
  animation: bound-anim 0.8s 0.2s infinite;
  position: absolute;
}

.b-section__title-box__title__4 {
  left: 4.2em;
  animation: bound-anim 0.8s 0.3s infinite;
  position: absolute;
}

.b-section__title-box__title__5 {
  left: 5.6em;
  animation: bound-anim 0.8s 0.4s infinite;
  position: absolute;
}

@keyframes bound-anim {
  0%, 100% {
    top: 0;
    transform: scale(1);
  }
  30% {
    top: -25%;
  }
  50% {
    transform: scale(1);
  }
  90% {
    top: 0;
    transform: scale(1.2, 0.8);
  }
}
.b-section__messege-img {
  position: relative;
}

.b-section__messege-img::after {
  position: absolute;
  content: "";
  width: 172px; /*横幅*/
  height: 192px; /*縦幅*/
  background: #95DBDC; /*背景色*/
  z-index: -10;
  bottom: -70px;
  right: -30px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}

.b-section__business-img {
  position: relative;
}

.b-section__business-img::after {
  position: absolute;
  content: "";
  width: 172px; /*横幅*/
  height: 192px; /*縦幅*/
  background: rgb(251, 247, 199); /*背景色*/
  top: -30px;
  left: -100px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}

.b-section__environment-img {
  position: relative;
}

.b-section__environment-img::before {
  position: absolute;
  content: "";
  width: 192px; /*横幅*/
  height: 172px; /*縦幅*/
  background: #95DBDC; /*背景色*/
  z-index: 10;
  bottom: -70px;
  right: -30px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}

.b-section__philosophy-img {
  position: relative;
}

.b-section__philosophy-img::before {
  position: absolute;
  content: "";
  width: 142px; /*横幅*/
  height: 128px; /*縦幅*/
  background: #95DBDC; /*背景色*/
  z-index: 0;
  bottom: -90px;
  left: -10px;
  animation: fluidrotate2 20s ease 0s infinite; /*アニメーションの設定*/
}

.b-section__philosophy-img::after {
  position: absolute;
  content: "";
  width: 192px; /*横幅*/
  height: 172px; /*縦幅*/
  background: rgb(251, 247, 199); /*背景色*/
  z-index: 10;
  bottom: -50px;
  left: -100px;
  animation: fluidrotate 30s ease 0s infinite; /*アニメーションの設定*/
}

@keyframes fluidrotate {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes fluidrotate2 {
  0%, 100% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  42% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  56% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  70% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  84% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
}
@media screen and (max-width: 1024px) {
  .b-section__inner {
    flex-wrap: wrap;
    max-width: 50rem;
    width: 100%;
  }
  .b-section__img {
    margin: auto;
  }
  .b-section__img img {
    border-radius: 30px;
  }
  .b-section__text-box {
    width: 100%;
    margin-right: 0;
  }
  .b-section__title-box__title {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .b-section__environment-img::before {
    display: none;
  }
  .b-section__business-img::after {
    display: none;
  }
  .b-section__philosophy-img::before {
    display: none;
  }
  .b-section__philosophy-img::after {
    display: none;
  }
}
/* ------------------------------ */
/* Sub Section */
/* ------------------------------ */
.b-sub-section {
  display: flex;
  align-items: center;
  gap: 40px;
}
.b-sub-section__contents {
  flex-shrink: 0;
  width: 64%;
}
.b-sub-section__contents__title {
  color: #2AB7B8;
  margin: 0px 0px 20px;
}
.b-sub-section__contents__title__main {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0px 0px 5px;
  border-bottom: 1px solid #2AB7B8;
}
.b-sub-section__contents__title__main__num {
  font-size: 2.125rem;
}
.b-sub-section__contents__title__main__txt {
  font-size: 1.75rem;
}
.b-sub-section__contents__title__sub {
  font-size: 0.875rem;
}
.b-sub-section__contents__txt {
  font-size: 1rem;
  line-height: 1.8;
}
.b-sub-section__img {
  width: 100%;
}
.b-sub-section:nth-child(odd) {
  flex-direction: row-reverse;
}
.b-sub-section:not(:last-child) {
  margin: 0px 0px 20px;
}
@media screen and (max-width: 768px) {
  .b-sub-section {
    flex-direction: column;
    gap: 20px;
  }
  .b-sub-section:nth-child(odd) {
    flex-direction: column;
  }
  .b-sub-section:not(:last-child) {
    margin: 0px 0px 30px;
  }
  .b-sub-section__contents {
    width: 100%;
  }
  .b-sub-section__contents__title__main__num {
    font-size: 1.5rem;
  }
  .b-sub-section__contents__title__main__txt {
    font-size: 1.25rem;
  }
  .b-sub-section__contents__title__sub {
    font-size: 0.75rem;
  }
  .b-sub-section__img {
    width: 60%;
  }
}

.b-linkarea {
  max-width: 700px;
  color: #2AB7B8;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 30px 20px 50px;
}

.b-linkarea__link {
  width: 100px;
  height: 100px;
  font-size: 0.875rem;
  border-radius: 50%;
  border: 2px dotted #2AB7B8;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.b-linkarea__link a {
  display: block;
  width: 100%;
  opacity: 1;
}

.b-linkarea__link a::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

.b-linkarea__link:hover {
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  color: #ffffff;
  transition: all 0.5s linear;
  border: 2px dotted #ffffff;
}

.b-linkarea__link:hover {
  transform: scale(1.1, 1.1);
  transition: 0.5s all;
}

@media screen and (max-width: 767px) {
  .b-linkarea {
    padding: 20px 10px 20px;
  }
}
.b-linkcodekids {
  background-color: #EAF4F4;
  border-radius: 295px 0 0 295px;
  margin-left: 20%;
  height: 448px;
}
.b-linkcodekids__inner {
  display: flex;
  align-self: center;
  max-width: 1300px;
  margin-top: 170px;
  align-items: center;
  justify-content: space-between;
  height: 450px;
}
.b-linkcodekids__text-box {
  width: 30%;
  padding-left: 20px;
}
.b-linkcodekids__image-box {
  display: flex;
  align-self: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 40%;
  z-index: 10;
}
.b-linkcodekids__illustration1 {
  width: 20%;
  z-index: 10;
  align-self: flex-end;
}
.b-linkcodekids__image1 {
  width: 100%;
  flex-grow: 2;
  border-radius: 50px;
}
.b-linkcodekids__image2 {
  border-radius: 25px;
  width: 40%;
}
.b-linkcodekids__image3 {
  border-radius: 25px;
  width: 50%;
  height: 100%;
  align-self: flex-end;
}

.b-linkcodekids__text-box {
  position: relative;
}

.b-linkcodekids__text-box::after {
  position: absolute;
  content: "";
  width: 138px; /*横幅*/
  height: 153px; /*縦幅*/
  background: rgb(251, 247, 199); /*背景色*/
  z-index: -10;
  top: -70px;
  right: -230px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}

.b-linkcodekids__image-box {
  position: relative;
}

.b-linkcodekids__image-box::after {
  position: absolute;
  content: "";
  width: 201px; /*横幅*/
  height: 181px; /*縦幅*/
  background: rgb(251, 247, 199); /*背景色*/
  z-index: -10;
  bottom: -50px;
  left: 30px;
  animation: fluidrotate2 20s ease 0s infinite; /*アニメーションの設定*/
}

@keyframes fluidrotate {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes fluidrotate2 {
  0%, 100% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  42% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  56% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  70% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  84% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
}
@media screen and (max-width: 1024px) {
  .b-linkcodekids__inner {
    flex-wrap: wrap-reverse;
    margin-top: 50px;
    height: 100%;
    width: 90%;
    margin: auto;
    justify-content: center;
    padding-top: 50px;
  }
  .b-linkcodekids__image-box {
    margin: 0;
    width: 100%;
    padding-top: 30px;
  }
  .b-linkcodekids__text-box {
    width: 90%;
    padding: 0;
    order: 1;
  }
  .b-linkcodekids {
    margin-left: 0;
  }
  .b-linkcodekids__text-box::after {
    display: none;
  }
  .b-linkcodekids__image-box::after {
    display: none;
  }
  .b-linkcodekids {
    height: 100%;
    border-radius: 0 0 0 130px;
    margin-top: 50px;
  }
  .b-linkcodekids__illustration1 {
    display: none;
  }
  .b-linkcodekids__image1 {
    border-radius: 10px;
  }
  .b-linkcodekids__image2 {
    border-radius: 10px;
  }
  .b-linkcodekids__image3 {
    border-radius: 10px;
  }
}
.b-title-box-g {
  color: #2AB7B8;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.b-title-box-g__bg {
  width: 97px;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateY(-50%) translateX(-50%);
  z-index: -1;
}

.b-title-box-g__title {
  font-size: 2.25rem;
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  .b-title-box-g__title {
    font-size: 1.625rem;
  }
}
.b-title-box-w {
  color: #2AB7B8;
  margin: 0 auto;
  position: relative;
}

.b-title-box-w__title {
  font-size: 2.25rem;
  position: absolute;
  top: 135px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: #2AB7B8;
}

.b-title-box-w__bg {
  width: 87px;
  position: absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  top: 120px;
}

.b-title-box-w__subtitle {
  position: absolute;
  top: 165px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: #2AB7B8;
}

.b-work {
  margin-top: 100px;
}

.b-work__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  position: relative;
  padding: 0 60px;
  max-width: 81.25rem;
  width: 100%;
  margin: 0px auto;
}

.b-work__inner {
  position: relative;
}

.b-work__inner::before {
  position: absolute;
  content: "";
  background-color: #95DBDC;
  border-radius: 130px 0 0 130px;
  width: 95%;
  height: 85%;
  z-index: -10;
  right: 0;
  bottom: 30px;
}

.b-work__box {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  max-width: calc(33.333% - 3.3333333333rem);
  position: relative;
  background-color: #fff;
  padding: 20px 17px;
  border-radius: 15px;
  box-shadow: 0px 0px 3px rgba(42, 183, 184, 0.2);
}

.b-work__box__day {
  font-size: 0.75rem;
}

.b-work__box__text-box {
  margin: 10px 0px 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.b-work__box__title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 0.875rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.b-work__box__img {
  text-align: center;
  padding-top: 66.666%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.b-work__box__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease;
}

.b-work__box__label {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
  padding: 0px 20px;
  background-color: #2AB7B8;
  border-radius: 15px;
  z-index: 1;
}
.b-work__box__label--spfirst::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../images/common/icon-sp.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.b-work__box:hover .b-work__box__img img {
  transform: translate(-50%, -50%) scale(1.2);
}
.b-work__box:hover .b-btn-arrow.b-btn-arrow--white .b-btn-arrow__icon {
  background: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  border: solid 2px #fff;
}
.b-work__box:hover .b-btn-arrow.b-btn-arrow--white .b-btn-arrow__icon::before {
  background: url(../images/common/arrow-white.png) no-repeat center center/contain;
}

.b-work__box__text-box .b-btn-arrow.b-btn-arrow--white {
  margin-top: auto;
}
.b-work__box__text-box .b-btn-arrow {
  margin-top: auto;
}

.b-work__box__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
  height: 36px;
  overflow: hidden;
  z-index: 3;
}
.b-work__box__tags__item a {
  display: flex;
  align-items: center;
  height: 36px;
  width: fit-content;
  padding: 0px 10px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #2AB7B8;
  background-color: #fff;
  border: 1px solid #2AB7B8;
  border-radius: 18px;
}

.b-work__box__tag {
  position: absolute;
  display: inline-block;
  background-color: #2AB7B8;
  border-radius: 30px;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 6px 16px;
  top: 10%;
  left: 5%;
  z-index: 1000;
}
.b-work__box__tag a {
  position: relative;
  z-index: 1000;
}

.b-work__box__link {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  box-sizing: border-box;
  transition: 0.3s ease;
}

.b-work__ring {
  position: relative;
}

.b-work__ring__img {
  position: absolute;
  width: 280px;
  left: 10%;
  animation: rotate-anime 20s linear infinite;
}

@keyframes rotate-anime {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.b-work__ring__img2 {
  position: absolute;
  width: 260px;
  right: 10%;
  top: 520px;
  animation: rotate-anime 15s linear infinite;
}

@media screen and (max-width: 1024px) {
  .b-work__wrap {
    padding: 30px 20px;
    max-width: 50rem;
    width: 100%;
  }
  .b-work__box {
    width: 48%;
  }
  .b-work__box {
    max-width: calc(50% - 1.3125rem);
    width: 100%;
  }
  .b-news__inner .b-work__box {
    max-width: calc(33.333% - 1.75rem);
    width: 100%;
    position: relative;
  }
  .b-work__inner::before {
    border-radius: 0 0 0 130px;
    width: 100%;
  }
  .b-work__ring__img {
    width: 140px;
  }
  .b-work__box__tag {
    padding: 5px 10px;
  }
  .b-work__box {
    width: 100%;
  }
  .b-work__box__text-box {
    margin-bottom: 16px;
  }
  .b-work__box__text-box .b-btn-arrow {
    margin-top: auto;
  }
  .b-work__ring__img {
    display: none;
  }
  .b-work__ring__img2 {
    display: none;
  }
  .b-work__box__img img {
    border-radius: 10px;
  }
}
@media screen and (max-width: 600px) {
  .b-work__inner::before {
    height: 95%;
  }
  .b-work__wrap {
    max-width: 31.25rem;
    width: 100%;
  }
  .b-work__box {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .b-news__inner .b-work__wrap {
    max-width: 28.125rem;
    width: 100%;
  }
  .b-news__inner .b-work__box {
    max-width: 100%;
  }
  .b-news__inner .b-work__box:not(:first-child) {
    margin-left: 0;
    margin-top: 2rem;
  }
  .b-news__inner .b-work__box:nth-child(3n+1) {
    margin-left: 0;
  }
  .b-news__inner .b-work__box:nth-child(n+3) {
    margin-top: 2rem;
  }
}
.b-work__wrap__text {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 100px;
  color: black;
}

.b-news {
  margin-top: 70px;
  padding-top: 100px;
  position: relative;
}
.b-news__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 220px;
  padding: 0 20px;
  max-width: 1300px;
  margin: 220px auto 0;
}
.b-news__box {
  margin-bottom: 48px;
  width: 25%;
}
.b-news__box__day {
  font-size: 0.75rem;
}
.b-news__box__textbox {
  margin-top: 16px;
}
.b-news__title {
  margin-top: 16px;
}
.b-news__box__textbox {
  margin-top: 16px;
}
.b-news__box__img img {
  border-radius: 15px;
  display: inline-block;
}
.b-news__box__img {
  position: relative;
}
.b-news__illustration-box {
  width: 300px;
  z-index: 10;
  margin-left: 60px;
  top: 72px;
  position: absolute;
}

.b-news__box__img:hover {
  transform: scale(1.1, 1.1);
  transition: 0.5s all;
}

.b-news__box__img {
  height: 200px;
}

.b-news__box__img img {
  object-fit: cover;
  height: 100%;
}

.b-news::before {
  position: absolute;
  content: "";
  background-color: #EAF4F4;
  border-radius: 0 130px 130px 0;
  width: 90%;
  height: 100%;
  z-index: -10;
}

.b-news__inner::before {
  position: absolute;
  content: "";
  width: 242px; /*横幅*/
  height: 218px; /*縦幅*/
  background: rgb(251, 247, 199); /*背景色*/
  z-index: -10;
  bottom: -280px;
  left: 20px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}

@keyframes fluidrotate {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
.b-news__box__tag {
  position: absolute;
  display: inline-block;
  background-color: #2AB7B8;
  border-radius: 30px;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 6px 16px;
  top: 10%;
  left: 5%;
}

@media screen and (max-width: 768px) {
  .b-news__inner {
    display: block;
    margin-top: 0;
    padding: 190px 20px 0;
  }
  .b-news__box {
    width: 100%;
  }
  .b-news {
    margin-top: 50px;
    padding-top: 0;
  }
  .b-news::before {
    width: 100%;
    border-radius: 130px 0 0 0;
  }
  .b-news__inner::before {
    bottom: -200px;
    width: 192px;
    height: 168px;
  }
}
.b-recruit {
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  color: #ffffff;
  border-radius: 130px 0 0 0;
  margin-top: 80px;
  padding-bottom: 145px;
  padding-top: 56px;
}

.b-recruit__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  text-align: center;
  margin: auto;
}

.b-recruit__text {
  border: dashed 2px #ffffff; /*点線*/
  padding: 48px 180px;
  flex-grow: 2;
  border-radius: 50px;
}

.b-recruit__title {
  font-size: 2.5rem;
  font-weight: bold;
  background-image: none;
}

.b-recruit__subtitle {
  font-size: 1rem;
}

@media screen and (max-width: 1024px) {
  .b-recruit__inner {
    padding: 0 20px;
    max-width: 600px;
    width: 100%;
    margin: auto;
  }
  .b-recruit__text {
    padding: 50px 20px;
  }
  .b-recruit__title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 0;
  }
}
.b-footer {
  background-color: #EAF4F4;
  border-radius: 0 130px 0 0;
  margin-top: -120px;
}

.b-footer__nav__link a {
  font-size: 1rem;
  color: #2AB7B8;
  font-weight: bold;
}

.b-footer__nav__link {
  padding-top: 16px;
}

.b-footer__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  padding: 80px 20px 100px;
}

.b-footer__nav {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 360px;
  height: 200px;
  text-align: center;
}

.b-footer__nav__link a {
  text-align: left;
}

.b-footer__logo-box {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.b-footer__logo-box__logo {
  width: 100px;
  padding-bottom: 20px;
}

.b-footer__fb {
  width: 45px;
  display: inline-block;
}

.b-footer__insta {
  width: 45px;
  display: inline-block;
}

.b-footer__copy {
  color: #A9A9A9;
  text-align: center;
  display: block;
  padding-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .b-footer__inner {
    display: block;
  }
  .b-footer__logo-box {
    margin: auto;
  }
  .b-footer__nav {
    margin: auto;
    padding-top: 48px;
    display: block;
  }
  .b-footer__nav__link {
    padding: 0 0 20px;
  }
  .b-footer__nav {
    width: 100%;
    height: 300px;
    align-content: space-between;
  }
  .b-recruit__bg-round1 {
    display: none;
  }
}
.b-page-about {
  /*
  &__fv__Box-title::before {
  content: '';
  position: absolute;
  background-image: url(../images/common/title-bg.png);
  width: 330px;
  top: 11%;
  left: 0%;
  height: 80%;
  background-repeat:  no-repeat;                         /* 画像の繰り返しを指定  */
}
.b-page-about__fv {
  background: #ffffff;
  height: 547px;
}
.b-page-about__about {
  background-color: #ffffff;
}
.b-page-about__fv__Box {
  position: relative;
}
.b-page-about__fv__Box-title {
  display: inline-block;
  font-size: 2.5rem;
  color: #2AB7B8;
  padding: 12px 10px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateY(-50%);
  letter-spacing: 0.1em;
  min-width: 330px;
  text-align: center;
}
.b-page-about__fv__Box-title::after {
  content: "";
  position: absolute;
  background: url(../images/common/title-bg.png) no-repeat center center/contain;
  width: 120%;
  height: 120%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -10;
}

.b-page-about__secondary {
  margin-top: 100px;
  padding-bottom: 80px;
}
.b-page-about__secondary__inner {
  position: relative;
}
.b-page-about__secondary__inner::before {
  position: absolute;
  content: "";
  background-color: #eaf4f4;
  border-radius: 0 130px 130px 0;
  width: 97%;
  height: 100%;
  z-index: -10;
  padding-bottom: 100px;
}

.b-page-about__secondary__box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 20px 0;
}
.b-page-about__secondary__box__image {
  width: 30rem;
  margin-right: 4%;
}
.b-page-about__secondary__box__image img {
  border-radius: 60px;
  width: 100%;
}
.b-page-about__secondary__box__text {
  width: 30%;
  line-height: 1.8;
}

@media screen and (max-width: 800px) {
  .b-page-about__fv__Box-title {
    left: 25%;
  }
}
@media screen and (max-width: 600px) {
  .b-page-about__fv__Box-title {
    left: 15%;
    font-size: 1.5rem;
    min-width: 64vw;
  }
}
@media screen and (max-width: 767px) {
  .b-page-about__secondary__box {
    flex-wrap: wrap-reverse;
    padding-top: 50px;
    max-width: 37.5rem;
    width: 100%;
    margin: auto;
  }
  .b-page-about__secondary__box__image {
    margin-right: 0;
    width: 100%;
    margin-top: 20px;
  }
  .b-page-about__secondary__box__text {
    width: 100%;
  }
  .b-page-about__secondary__inner::before {
    border-radius: 0 0 130px 0;
    width: 100%;
  }
  .b-page-about__secondary__box__image.u-ml__5 {
    margin-left: 0;
  }
}
.b-day {
  background-image: url(../images/common/page-about-bg.png);
  background-repeat: no-repeat;
  background-position: center;
}
.b-day__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 50px auto 0;
  padding: 0 20px;
}
.b-day__box {
  width: 300px;
  position: relative;
}
.b-day__box__time {
  font-size: 1.25rem;
  color: #ffffff;
  background-color: #95DBDC;
  padding: 2px 4px;
  display: block;
  width: 88px;
  text-align: center;
  border-radius: 4px;
  margin-top: 12px;
}
.b-day__box__number {
  font-size: 2rem;
  font-weight: bold;
  background-image: url(../images/common/day-bg.svg);
  background-repeat: no-repeat;
  display: block;
  color: #ffffff;
  background-size: cover;
  width: 76px;
  height: 67px;
  line-height: 67px;
  left: -10px;
  text-align: center;
}
.b-day__box__text {
  margin-bottom: 30px;
}
.b-day__box__main-image {
  border-radius: 30px;
  box-shadow: -16px -16px 0px 0px rgb(251, 247, 199);
}
.b-day__box__number {
  width: 76px;
  position: absolute;
  top: -30px;
}

@media screen and (max-width: 768px) {
  .b-day__inner {
    display: block;
  }
  .b-day__box {
    margin: auto;
    width: 260px;
  }
  .b-day__box__number {
    font-size: 20px;
  }
  .b-day__box__time {
    font-weight: bold;
    font-size: 16px;
  }
}
.b-page {
  padding: 0 1.5625rem;
}

.b-page__inner {
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
  padding: 6.25rem 0 6.25rem;
  position: relative;
}

.b-page__contents h2 {
  display: block;
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: left;
}
.b-page__contents h3 {
  display: block;
  border-bottom: 2px solid #2ab7b8;
  font-size: 18px;
  font-weight: bold;
  padding: 4px 20px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
}
.b-page__contents h4 {
  display: block;
  border-bottom: 2px solid rgb(234, 244, 244);
  font-weight: bold;
  padding: 4px 20px;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 8px;
  text-align: left;
}
.b-page__contents a {
  color: #2ab7b8;
  cursor: pointer;
}
.b-page__contents img {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .b-page__inner {
    max-width: 37.5rem;
    padding: 5rem 0 5rem;
  }
}
.b-page-section {
  position: relative;
}

.b-page-section__inner::before {
  content: none;
}

.b-page-section::after {
  position: absolute;
  content: "";
  background-color: #EAF4F4;
  width: 100%;
  height: 100%;
  z-index: -10;
  top: 0px;
  height: 420px;
  left: 0;
  border-radius: 0 0 100px 0;
}

.b-page-section__box {
  color: #333333;
}

.b-pagination {
  text-align: center;
  margin: 64px auto 0;
  max-width: 1300px;
}

.pagination { /* 全体 */
  margin: 20px 0;
  font-size: 12px;
  font-weight: 700;
}

.page-numbers { /* ボタン */
  margin: 0 6px 0 0;
  display: inline-block;
  border: solid 1px #eee;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  transition: all 0.4s ease;
}

.page-numbers.current,
a.page-numbers:hover { /* カレント数字 */
  border: none;
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  color: #fff;
  border: solid 1px #eee;
  opacity: 1;
}

a.page-numbers { /* マウスオーバー */
  background: #fff;
  color: #2AB7B8;
  border-radius: 50px;
}

.b-header-second {
  background-color: rgb(234, 244, 244);
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  border-radius: 50px;
  height: 90px;
  background-color: #fff;
  padding: 0 1.25rem;
  position: fixed;
  top: -100px;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.visible {
  margin: auto;
  width: 100%;
  top: 20px;
  transition: 0.5s; /* アニメーションタイミング */
}

.b-header-second__logo {
  width: 60px;
  height: 68px;
  margin-left: 1.5rem;
}

.b-header-second__logo a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
}

@media screen and (max-width: 767px) {
  .b-header-second {
    display: none;
  }
}
.b-fv-second {
  background-color: rgb(234, 244, 244);
  padding: 1.25rem;
}
.b-fv-second__ring-l {
  width: 12.5rem;
}
.b-fv-second__ring-r {
  width: 16rem;
}
.b-fv-second__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 76.25rem;
  margin: auto;
}
.b-fv-second__title {
  background-image: url(../images/common/page-title-bg.png);
  color: #2AB7B8;
  font-size: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  width: 330px;
  text-align: center;
  padding: 12px 0px;
}
.b-fv-second__box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.b-fv-second__subtitle {
  font-size: 0.9375rem;
  color: #2AB7B8;
}

.b-fv-second__title {
  font-size: 22px;
}

@media screen and (max-width: 768px) {
  .b-fv-second {
    height: auto;
  }
  .b-fv-second__inner img {
    min-width: 0;
  }
  .b-fv-second__subtitle {
    min-width: 0;
  }
}
.b-single-main {
  background-color: rgb(234, 244, 244);
  padding-bottom: 12.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.b-single-main__inner {
  max-width: 73.75rem;
  background-color: #fff;
  border-radius: 30px;
  margin: auto;
  padding: 3.5rem 5.9375rem;
}
.b-single-main__fv {
  max-width: 60.625rem;
  margin: auto;
}
.b-single-main__box {
  margin-top: 2rem;
}
.b-single-main__box__title {
  display: block;
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.625rem 0;
  width: 13.3125rem;
  text-align: center;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.b-single-main__box__text {
  font-size: 1rem;
}
.b-single-main__box__text p:not(:last-child) {
  margin-bottom: 1rem;
}
.b-single-main__design-wrap__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.b-single-main__box-design {
  width: 30%;
}
.b-single-main__box-design__title {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.b-single-main__img-wrap {
  padding-top: 68%;
  position: relative;
  position: relative;
  overflow: hidden;
}
.b-single-main__img-wrap__label {
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 0px 22px;
  background-color: #2AB7B8;
  z-index: 1;
  border-radius: 20px;
}
.b-single-main__img-wrap__label::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../images/common/icon-sp.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.b-single-main__img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.b-single-main__box-design__img {
  width: 100%;
  height: 100%;
  margin: auto;
}

.client-box {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  border-bottom: 1px dotted #D9D9D9;
  padding: 1rem 0;
}
.client-box__first {
  padding-left: 1.5rem;
}
.client-box__second {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.b-single-main__box__title {
  font-size: 18px;
  text-align: center;
}

.b-single-main__box-design__title {
  font-size: 16px;
  text-align: center;
}

.b-single-main__box-design {
  text-align: center;
}

.b-single-main__box-design #openModal,
.b-single-main__box-design #openModal2,
.b-single-main__box-design #openModal3 {
  cursor: pointer;
}

.client-box__second.client-box__url {
  color: #2AB7B8;
  border-bottom: 1px solid #2AB7B8;
}

@media screen and (max-width: 1024px) {
  .b-single-main__inner {
    padding: 50px 20px;
  }
  .b-single-main__design-wrap__inner {
    display: block;
  }
  .client-box {
    display: block;
  }
  .client-box__second {
    padding: 0;
    font-size: 18px;
  }
  .client-box__first {
    padding: 0;
    font-size: 18px;
  }
  .b-single-main__box-design {
    margin-top: 10px;
    width: 100%;
  }
  .b-single-main__box-design__title {
    margin-bottom: 0;
  }
}
.b-single-main__title {
  color: #2AB7B8; /*文字色*/
  padding: 0.5em 0; /*上下の余白*/
  border-top: solid 3px #2AB7B8; /*上線*/
  border-bottom: solid 3px #2AB7B8; /*下線*/
}

.b-single-main__body img {
  margin: 10px auto;
}

@media screen and (max-width: 767px) {
  .b-single-main__box-design #openModal,
.b-single-main__box-design #openModal2,
.b-single-main__box-design #openModal3 {
    display: none;
  }
  .b-single-main__img-wrap__label {
    top: 10px;
    left: 10px;
    font-size: 10px;
    height: 24px;
    padding: 0px 10px;
  }
  .b-single-main__img-wrap__label::before {
    width: 16px;
    height: 16px;
  }
}
.b-other__box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 0;
  max-width: 68.75rem;
  width: 100%;
  margin: auto;
}

.b-other__box__image {
  flex: 0 1 44%;
  margin-left: 4%;
}
.b-other__box__image img {
  border-radius: 60px;
  width: 100%;
}

.b-other__box__text {
  flex: 0 0 52%;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .b-other__box {
    flex-wrap: wrap-reverse;
    padding-top: 50px;
    max-width: 37.5rem;
    width: 100%;
    margin: auto;
  }
  .b-other__box__image {
    margin-right: 0;
    width: 100%;
    flex: auto;
    margin-top: 20px;
  }
  .b-other__box__text {
    width: 100%;
    flex: auto;
  }
}
.b-requirement__wrap {
  max-width: 82%;
  margin-left: auto;
  margin-top: 80px;
  padding: 0 20px;
}
.b-requirement__wrap__title {
  display: inline-block;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  color: #fff;
  height: 5rem;
  line-height: 5rem;
  padding-left: 3.125rem;
  border-radius: 15px;
}

.b-requirement__box {
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding: 0 20px 24px;
  border-bottom: 1px dotted #EAF4F4;
}
.b-requirement__box__title {
  font-size: 1.25rem;
  width: 30%;
  display: inline-block;
}
.b-requirement__box__text {
  font-size: 1rem;
  display: inline-block;
}

.b-requirement {
  margin-bottom: 150px;
}

@media screen and (max-width: 1024px) {
  .b-requirement__box {
    display: block;
  }
  .b-requirement__wrap {
    width: 100%;
  }
  .b-requirement__wrap {
    max-width: 100%;
  }
  .b-requirement__box__text {
    padding-top: 10px;
  }
  .b-requirement__wrap__title {
    height: 4rem;
    line-height: 4rem;
    font-size: 1.125rem;
    padding-left: 20px;
  }
}
.b-requirement__box__title__norecruitment {
  width: 100%;
  padding-top: 30px;
  padding-left: 20px;
}

.b-privacy-policy {
  margin-top: 48px;
  padding: 0 20px;
}
.b-privacy-policy__inner {
  border: solid 6px #EAF4F4;
  max-width: 1200px;
  border-radius: 20px;
  margin: auto;
  padding: 48px;
}
.b-privacy-policy__text {
  margin-top: 18px;
  text-align: center;
}

.b-privacy-policy__box {
  margin-top: 32px;
}
.b-privacy-policy__box__title {
  font-size: 1.25rem;
  position: relative;
  padding-left: 16px;
  font-size: 0.9375rem;
}
.b-privacy-policy__box__title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 35px;
  background-color: #2AB7B8;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.b-privacy-policy__box__text {
  margin-top: 12px;
  font-size: 0.9375rem;
}

@media screen and (max-width: 768px) {
  .b-privacy-policy__inner {
    padding: 0 20px 30px;
  }
}
.b-contact {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.b-contact__inner {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}
.b-contact__text {
  text-align: center;
  font-size: 1.25rem;
}

.b-contact__header::after {
  background-color: #fff;
}

/*ContactForm7カスタマイズ*/
#cf-tbl {
  margin-top: 50px;
}

#cf-tbl table {
  width: 100%;
  border-collapse: collapse;
  border-width: 1px;
}

#cf-tbl table tr th,
#cf-tbl table tr td {
  padding: 0.5em;
  text-align: left;
  vertical-align: top;
  border-width: 1px;
  vertical-align: middle;
}

#cf-tbl table tr th {
  width: 35%;
}

@media screen and (max-width: 768px) {
  #cf-tbl {
    width: 100%;
  }
  #cf-tbl table,
#cf-tbl table tbody,
#cf-tbl table tr,
#cf-tbl table tr th,
#cf-tbl table tr td {
    display: block;
  }
  #cf-tbl table {
    width: 100%;
    border-width: 0 0 1px 0;
  }
  #cf-tbl table tr th {
    width: 100%;
    padding: 2% 5% 1%;
  }
  #cf-tbl table tr td {
    width: 100%;
    padding: 1% 5% 2%;
  }
  #cf-tbl table tr td {
    border-width: 0px 1px 0px 1px;
  }
}
/*「必須」文字デザイン*/
.required {
  font-size: 0.8em;
  padding: 5px 8px;
  background: #EA5550;
  color: #fff;
  border-radius: 10px;
  margin-right: 5px;
}

/* 入力項目を見やすく */
input.wpcf7-form-control.wpcf7-text,
textarea.wpcf7-form-control.wpcf7-textarea {
  width: 100%;
  padding: 8px 15px;
  margin-right: 10px;
  border-radius: 3px;
  background-color: #EAF4F4;
}

textarea.wpcf7-form-control.wpcf7-textarea {
  height: 200px;
}

/* 「送信する」ボタン */
input.wpcf7-submit {
  display: block;
  padding: 15px;
  width: 400px;
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 2px;
  margin: 15px auto 0;
  border-radius: 30px;
  transition: all 0.4s ease;
}

@media screen and (max-width: 768px) {
  input.wpcf7-submit {
    width: 250px;
  }
}
@media screen and (max-width: 1024px) {
  .b-contact {
    width: 100%;
  }
}
input.wpcf7-submit:hover {
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-4px);
  opacity: 0.7;
}

/* エラーメッセージを見やすく */
span.wpcf7-not-valid-tip,
.wpcf7-response-output.wpcf7-validation-errors {
  color: red;
  font-weight: 600;
}

.checkbox-color {
  color: #2AB7B8;
  text-align: center;
}

/* チェックボタンカスタマイズ*/
.wpcf7-form-control-wrap {
  display: block;
  text-align: center;
}

span.wpcf7-list-item {
  margin: 0 30px 0 0; /* 項目右側の余白設定と、デフォルトの左側の余白を打ち消す */
  position: relative;
}

.wpcf7-list-item-label {
  cursor: pointer; /* labelにhoverした時にカーソルを表示させる */
  font-size: 16px; /* 項目のフォントサイズ */
}

input[type=checkbox] {
  opacity: 0; /* デフォルトのチェックボックスを見えなくする */
  position: absolute;
}

.wpcf7-acceptance a {
  border-bottom: #333 1px solid;
}

.wpcf7-list-item-label::before {
  /* チェックボックスのデザイン */
  border: 1px solid #333;
  content: "";
  display: inline-block;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 15px;
}

input[type=checkbox]:checked + .wpcf7-list-item-label::after {
  /* チェックアイコン */
  background-image: url(../images/common/check.png);
  background-size: contain;
  content: "";
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 15px;
  height: 15px;
}

input[type=checkbox]:checked + .wpcf7-list-item-label::before {
  /* チェックしたボックスの背景色を設定 */
  background-color: #EAF4F4;
}

.dropmenu {
  width: 100%;
  padding: 8px 15px;
  margin-right: 10px;
  border-radius: 3px;
  background-color: #EAF4F4;
}

.b-contact__bottom {
  max-width: 62.5rem;
  width: 100%;
  margin: 6.25rem auto 0;
}

.b-contact__card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 62.5rem;
  width: 100%;
  margin: 0 auto;
}

.b-contact__card {
  max-width: calc(25% - 1.5rem);
  width: 100%;
}

.b-contact__card:not(:first-child) {
  margin-left: 2rem;
}

.b-contact__card:hover .b-contact__card__img img {
  transform: translate(-50%, -50%) scale(1.2);
}

.b-contact__card__img {
  text-align: center;
  padding-top: 66.666%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.b-contact__card__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease;
}

.b-contact__card__tag {
  position: absolute;
  display: inline-block;
  background-color: #2AB7B8;
  border-radius: 30px;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 6px 16px;
  top: 10%;
  left: 5%;
  z-index: 1000;
}
.b-contact__card__tag a {
  position: relative;
  z-index: 1000;
}

.b-contact__card__text-box {
  padding: 0.75rem 0.25rem;
  min-height: 6.25rem;
  display: flex;
  flex-direction: column;
}

.b-contact__card__day {
  font-size: 0.625rem;
}

.b-contact__card__title {
  margin-top: 16px;
  font-weight: 500;
  font-size: 0.875rem;
  color: #2AB7B8;
}

@media screen and (max-width: 767px) {
  .b-contact__card-wrap {
    max-width: 37.5rem;
  }
  .b-contact__card {
    max-width: calc(50% - 0.75rem);
  }
  .b-contact__card:not(:first-child) {
    margin-left: 1.5rem;
  }
  .b-contact__card:nth-child(2n+1) {
    margin-left: 0;
  }
  .b-contact__card:nth-child(n+3) {
    margin-top: 2rem;
  }
  .b-contact__card__tag {
    font-size: 0.5rem;
    font-weight: bold;
    padding: 3px 8px;
  }
  .b-contact__card__text-box {
    min-height: 4.375rem;
  }
}
/* Loading背景画面設定　*/
#splash {
  /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgb(234, 244, 244);
  text-align: center;
  color: #fff;
}

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Loading アイコンの大きさ設定　*/
#splash_logo img {
  width: 260px;
}

/*========= 画面遷移のためのCSS ===============*/
/*画面遷移アニメーション*/
.splashbg {
  display: none;
}

/*bodyにappearクラスがついたら出現*/
body.appear .splashbg {
  display: block;
  content: "";
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform: scaleY(0);
  background-color: #333; /*伸びる背景色の設定*/
  animation-name: PageAnime;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes PageAnime {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  50% {
    transform-origin: top;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}
/*画面遷移の後現れるコンテンツ設定*/
#container {
  opacity: 0; /*はじめは透過0に*/
}

/*bodyにappearクラスがついたら出現*/
body.appear #container {
  animation-name: PageAnimeAppear;
  animation-duration: 1s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes PageAnimeAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* fadeUp */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 下から */
/* fadeUpをするアイコンの動き */
.fadeUp2 {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
  display: block;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeUpTrigger {
  opacity: 0;
}

.delay-time02 {
  animation-delay: 0.2s;
}

.delay-time04 {
  animation-delay: 0.4s;
}

.delay-time06 {
  animation-delay: 0.6s;
}

.delay-time08 {
  animation-delay: 0.8s;
}

/* zoomIn  */
.zoomIn {
  animation-name: zoomInAnime;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes zoomInAnime {
  from {
    opacity: 1;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.zoomInTrigger {
  opacity: 0;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Avenir", "Helvetica, Neue", "Helvetica", "Arial";
}

/* モーダルCSS */
.b-modal__Area {
  display: none;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.b-modal__Bg {
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.9);
}

.b-modal__Wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 100%;
  padding: 10px 30px;
  background-color: #fff;
}

.closeModal {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
}

/* 以下ボタンスタイル */
button {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #282828;
  border-radius: 2px;
  cursor: pointer;
}

.b-btn-arrow {
  display: flex;
  margin-top: 3rem;
}

.b-btn-arrow__link {
  color: #2AB7B8;
  transition: all 0.4s ease;
}

.b-btn-arrow__icon {
  position: relative;
  display: inline-block;
  background-image: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 16px;
  vertical-align: middle;
  transition: all 0.4s ease;
}

.b-btn-arrow__icon::before {
  /* くの字の表示設定 */
  content: "";
  margin: auto;
  position: absolute;
  width: 16px; /* くの字を山なりに見た時、左側の長さ */
  height: 10px; /* くの字を山なりに見た時、右側の長さ */
  background: url(../images/common/arrow-white.png) no-repeat center center/contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
}

.b-btn-arrow__link:hover {
  opacity: 1;
}
.b-btn-arrow__link:hover .b-btn-arrow__icon {
  background: #ffffff;
  border: solid 1px #2AB7B8;
  transition: all 0.4s ease;
}
.b-btn-arrow__link:hover .b-btn-arrow__icon::before {
  background: url(../images/common/arrow-green.png) no-repeat center center/contain;
}

@media screen and (max-width: 1024px) {
  .b-btn-arrow {
    margin-top: 30px;
  }
}
.b-btn-arrow.b-btn-arrow--white {
  margin-top: 24px;
  display: flex;
}
.b-btn-arrow.b-btn-arrow--white .b-btn-arrow__link {
  color: #fff;
}
.b-btn-arrow.b-btn-arrow--white .b-btn-arrow__link.b-btn-arrow__base {
  color: #2AB7B8;
}
.b-btn-arrow.b-btn-arrow--white .b-btn-arrow__icon {
  background: #ffffff;
  border: 1px solid #2AB7B8;
  transition: all 0.2s ease;
}
.b-btn-arrow.b-btn-arrow--white .b-btn-arrow__icon::before {
  background: url(../images/common/arrow-green.png) no-repeat center center/contain;
}
.b-btn-arrow.b-btn-arrow--white .b-btn-arrow__link:hover .b-btn-arrow__icon {
  background: linear-gradient(90deg, rgb(18, 215, 179) 40%, rgb(42, 183, 184));
  border: solid 2px #fff;
}
.b-btn-arrow.b-btn-arrow--white .b-btn-arrow__link:hover .b-btn-arrow__icon::before {
  background: url(../images/common/arrow-white.png) no-repeat center center/contain;
}

@media screen and (max-width: 1024px) {
  .b-btn-arrow.b-btn-arrow--white {
    margin-top: 16px;
  }
}
.b-company {
  padding: 0 1.5625rem;
}

.b-company__inner {
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
  padding: 5rem 0 18.75rem;
  position: relative;
}

.b-company__inner::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 18.75rem;
  background: url(../images/common/illustration1.png) no-repeat center center/contain;
  width: 11.875rem;
  height: 15.6875rem;
}

.b-company__list {
  display: flex;
  width: 100%;
  padding-bottom: 36px;
  border-bottom: 4px #EAF4F4 dotted;
}

.b-company__list:not(:first-child) {
  margin-top: 2.625rem;
}

.b-company__list__title {
  font-size: 1.25rem;
  font-weight: 600;
  max-width: 6.25rem;
  width: 100%;
}

.b-company__list__text {
  font-size: 1.25rem;
  font-weight: 500;
  width: calc(100% - 100px);
  text-align: right;
}

@media screen and (max-width: 767px) {
  .b-company__inner {
    max-width: 37.5rem;
  }
  .b-company__list {
    display: block;
    padding-bottom: 12px;
  }
  .b-company__list:not(:first-child) {
    margin-top: 1.25rem;
  }
  .b-company__list__title {
    font-size: 1rem;
    max-width: 100%;
  }
  .b-company__list__text {
    font-size: 1rem;
    width: 100%;
    text-align: left;
    margin-top: 0.375rem;
  }
}
/* ------------------------------ */
/* Media */
/* ------------------------------ */
.b-media__title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0px 0px 20px;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 30px;
}
.b-media__title__icon {
  flex-shrink: 0;
  width: auto;
  height: 30px;
}
.b-media__title__txt {
  font-size: 1.25rem;
  word-break: break-all;
}
.b-media__thumbs {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.b-media__thumbs img {
  display: block;
  width: calc((100% - 40px) / 2);
}
.b-media__link {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .b-media__title {
    padding: 10px 20px;
    border-radius: 20px;
  }
  .b-media__title__icon {
    height: 20px;
  }
  .b-media__title__txt {
    font-size: 0.875rem;
  }
  .b-media__thumbs {
    gap: 20px;
  }
  .b-media__thumbs img {
    width: calc((100% - 20px) / 2);
  }
}

/* ------------------------------ */
/* Front Features */
/* ------------------------------ */
.b-front-features {
  position: relative;
  display: flex;
  align-items: center;
  gap: 80px;
  padding: 100px 40px;
  width: 1020px;
  max-width: 100%;
  margin: 160px auto;
}
.b-front-features__contents__title {
  margin: 0px 0px 30px;
}
.b-front-features__contents__title__main {
  display: block;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #2AB7B8;
  margin: 0px 0px 8px;
}
.b-front-features__contents__title__main::after {
  content: "";
  display: block;
  width: 36px;
  height: 2px;
  margin: 5px 0px 0px;
  background-color: #2AB7B8;
}
.b-front-features__contents__title__sub {
  font-size: 1rem;
  font-weight: 100;
  color: #2AB7B8;
}
.b-front-features__contents__txt {
  line-height: 1.8;
}
.b-front-features__img {
  flex-shrink: 0;
  width: 35%;
}
.b-front-features::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(50% - 64px);
  display: block;
  width: calc(100vw - 64px);
  height: 100%;
  background-color: rgb(234, 244, 244);
  z-index: -1;
  transform: translateX(-50%);
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
}
@media screen and (max-width: 1023px) {
  .b-front-features {
    flex-direction: column;
    gap: 40px;
    margin: 60px auto;
    padding: 60px 32px;
    width: 50rem;
  }
  .b-front-features__img {
    width: 320px;
    max-width: 100%;
  }
  .b-front-features::before {
    left: 50%;
    width: 100vw;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 130px;
  }
}

/* ------------------------------ */
/* Features Work List */
/* ------------------------------ */
.b-feature-work-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  width: 100%;
}
.b-feature-work-list__item {
  width: calc((100% - 40px) / 3);
}
.b-feature-work-list__item a {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  padding: 20px 18px;
  border-radius: 14px;
}
.b-feature-work-list__item__thumb {
  position: relative;
  margin: 0px 0px 10px;
}
.b-feature-work-list__item__thumb__img {
  display: block;
  width: 100%;
  height: 190px;
  object-fit: contain;
  margin: 0px auto;
}
.b-feature-work-list__item__thumb__label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
  padding: 0px 20px;
  border-radius: 15px;
  background-color: #2AB7B8;
}
.b-feature-work-list__item__thumb__label::before {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("../images/common/icon-sp.svg");
}
.b-feature-work-list__item__contents__title {
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0px 0px 20px;
}
.b-feature-work-list__item__contents__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0px 0px 10px;
}
.b-feature-work-list__item__contents__tags__item {
  display: flex;
  align-items: center;
  height: 36px;
  max-width: 100%;
  font-size: 0.75rem;
  font-weight: 700;
  color: #2AB7B8;
  padding: 0px 10px;
  border: 1px solid #2AB7B8;
  border-radius: 18px;
}
.b-feature-work-list__item__contents__tags__item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.b-feature-work-list__item__btn {
  margin: auto 0px 0px;
}
.b-feature-work-list__item:first-child {
  width: 100%;
}
.b-feature-work-list__item:first-child a {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}
.b-feature-work-list__item:first-child .b-feature-work-list__item__thumb__img {
  width: 90%;
  height: auto;
}
.b-feature-work-list__item:first-child .b-feature-work-list__item__thumb__label {
  height: 40px;
  font-size: 0.875rem;
  border-radius: 20px;
}
.b-feature-work-list__item:first-child .b-feature-work-list__item__contents__title {
  font-size: 1.125rem;
}
.b-feature-work-list__item:first-child .b-feature-work-list__item__contents__tags {
  gap: 10px;
}
.b-feature-work-list__item:first-child .b-feature-work-list__item__contents__tags__item {
  height: 40px;
  font-size: 0.875rem;
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .b-feature-work-list {
    gap: 20px 0px;
  }
  .b-feature-work-list__item {
    width: 100%;
  }
  .b-feature-work-list__item a .b-feature-work-list__item__thumb__img, .b-feature-work-list__item:first-child a .b-feature-work-list__item__thumb__img {
    height: 200px;
  }
  .b-feature-work-list__item a .b-feature-work-list__item__thumb__label, .b-feature-work-list__item:first-child a .b-feature-work-list__item__thumb__label {
    height: 30px;
    font-size: 0.875rem;
    border-radius: 15px;
  }
  .b-feature-work-list__item a .b-feature-work-list__item__contents__title, .b-feature-work-list__item:first-child a .b-feature-work-list__item__contents__title {
    font-size: 0.875rem;
  }
  .b-feature-work-list__item a .b-feature-work-list__item__contents__tags, .b-feature-work-list__item:first-child a .b-feature-work-list__item__contents__tags {
    gap: 5px;
  }
  .b-feature-work-list__item a .b-feature-work-list__item__contents__tags__item, .b-feature-work-list__item:first-child a .b-feature-work-list__item__contents__tags__item {
    height: 36px;
    font-size: 0.625rem;
  }
}

.b-404-page {
  text-align: center;
  padding: 0 1.5625rem;
}

.b-404-page__inner {
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
  padding: 5rem 0 9.375rem;
}

.b-404-page__title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  color: #2AB7B8;
}

.b-404-page__sub-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.b-404-page__text {
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .b-404-page__inner {
    max-width: 37.5rem;
    padding: 5rem 0 5rem;
  }
  .b-404-page__title {
    font-size: 1.5rem;
  }
  .b-404-page__sub-title {
    font-size: 1rem;
  }
  .b-404-page__text {
    font-size: 0.875rem;
  }
}
@-webkit-keyframes bounceInUpSmall {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 400px, 0) scaleY(1);
    transform: translate3d(0, 400px, 0) scaleY(1);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUpSmall {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 400px, 0) scaleY(1);
    transform: translate3d(0, 400px, 0) scaleY(1);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__bounceInUpSmall {
  -webkit-animation-name: bounceInUpSmall;
  animation-name: bounceInUpSmall;
}

@-webkit-keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUpSmall {
  -webkit-animation-name: fadeInUpSmall;
  animation-name: fadeInUpSmall;
}

/* ------------------------------ */
/* Utility */
/* ------------------------------ */
.u-mt__150 {
  margin-top: 150px;
}

.u-mt__100 {
  margin-top: 100px;
}

.u-mt__70 {
  margin-top: 70px;
}

.u-mt__50 {
  margin-top: 50px;
}

.u-mt__40 {
  margin-top: 40px;
}

.u-mt__20 {
  margin-top: 20px;
}

.u-mt__10 {
  margin-top: 10px;
}

.u-mt__-100 {
  margin-top: -100px;
}

.u-mt__0 {
  margin-top: 0;
}

.u-mb__80 {
  margin-bottom: 80px;
}

.u-mb__60 {
  margin-bottom: 60px;
}

.u-mb__40 {
  margin-bottom: 40px;
}

.u-mb__30 {
  margin-bottom: 30px;
}

.u-mb__20 {
  margin-bottom: 20px;
}

.u-mr__0 {
  margin-right: 0;
}

.u-ml__80 {
  margin-left: 80px;
}

.u-ml__5 {
  margin-left: 5%;
}

.u-ml__0 {
  margin-left: 0;
}

.u-m__auto {
  margin-left: auto;
  margin-right: auto;
}

.u-right {
  justify-content: flex-end;
}

.u-center {
  justify-content: center;
}

.u-row-reverse {
  flex-direction: row-reverse;
}

.u-color__g {
  color: #2AB7B8;
}

.u-fs__28 {
  font-size: 1.75rem;
}

.u-pt__130 {
  padding-top: 130px;
}

.u-pt__80 {
  padding-top: 80px;
}

.u-pt__50 {
  padding-top: 50px;
}

.u-pt__40 {
  padding-top: 2.5rem;
}

.u-pt__30 {
  padding-top: 30px;
}

.u-pt__0 {
  padding-top: 0;
}

.u-pb__100 {
  padding-bottom: 100px;
}

.u-pb__30 {
  padding-bottom: 30px;
}

.u-pr__0 {
  padding-right: 0;
}

.u-later__2 {
  animation-delay: 2s;
}

.u-animate__delay-2s {
  animation-delay: 0.2s; /*この数字を指定したい遅延時間に変更*/
}

.u-animate__delay-4s {
  animation-delay: 0.4s; /*この数字を指定したい遅延時間に変更*/
}

.u-animate__delay-6s {
  animation-delay: 0.6s; /*この数字を指定したい遅延時間に変更*/
}

.u-animate__delay-8s {
  animation-delay: 0.8s; /*この数字を指定したい遅延時間に変更*/
}

.u-animate__delay-10s {
  animation-delay: 1s; /*この数字を指定したい遅延時間に変更*/
}

.u-animate__delay-12s {
  animation-delay: 1.2s; /*この数字を指定したい遅延時間に変更*/
}

.u-opacity__0 {
  opacity: 0;
}

.u-opacity__1 {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .u-sp-ml__0 {
    margin-left: 0;
  }
  .u-sp-ml__auto {
    margin-left: auto;
  }
  .u-sp-pt__0 {
    padding-top: 0;
  }
  .u-sp__center {
    justify-content: center;
  }
  .u-top__-100 {
    top: -100px;
  }
  .u-top__-95 {
    top: -95px;
  }
  .u-top__-65 {
    top: -65px;
  }
  .u-top__-15 {
    top: -15px;
  }
  .u-top__10 {
    top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .u-sp-mt__0 {
    margin-top: 0px;
  }
  .u-sp-mt__10 {
    margin-top: 10px;
  }
  .u-sp-mt__20 {
    margin-top: 20px;
  }
  .u-sp-mt__30 {
    margin-top: 30px;
  }
  .u-sp-mt__40 {
    margin-top: 40px;
  }
  .u-sp-mt__50 {
    margin-top: 50px;
  }
  .u-sp-mt__60 {
    margin-top: 60px;
  }
  .u-sp-mt__70 {
    margin-top: 70px;
  }
  .u-sp-mt__80 {
    margin-top: 80px;
  }
  .u-sp-mt__90 {
    margin-top: 90px;
  }
  .u-sp-mt__100 {
    margin-top: 100px;
  }
  .u-sp-mb__0 {
    margin-bottom: 0px;
  }
  .u-sp-mb__10 {
    margin-bottom: 10px;
  }
  .u-sp-mb__20 {
    margin-bottom: 20px;
  }
  .u-sp-mb__30 {
    margin-bottom: 30px;
  }
  .u-sp-mb__40 {
    margin-bottom: 40px;
  }
  .u-sp-mb__50 {
    margin-bottom: 50px;
  }
  .u-sp-mb__60 {
    margin-bottom: 60px;
  }
  .u-sp-mb__70 {
    margin-bottom: 70px;
  }
  .u-sp-mb__80 {
    margin-bottom: 80px;
  }
  .u-sp-mb__90 {
    margin-bottom: 90px;
  }
  .u-sp-mb__100 {
    margin-bottom: 100px;
  }
  .u-sp-767__mt__60 {
    margin-top: 60px;
  }
}