/* ------------------------------ */
/* Media */
/* ------------------------------ */
@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-media {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0px 0px 20px;
    padding: 10px 20px;
    background-color: $white;
    border-radius: 30px;
  
    &__icon {
      flex-shrink: 0;
      width: auto;
      height: 30px;
    }
  
    &__txt {
      font-size: rem(20);
      word-break: break-all;
    }
  }

  &__thumbs {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  
    img {
      display: block;
      width: calc((100% - 40px) / 2);
    }
  }

  &__link {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 768px) {
    &__title {
      padding: 10px 20px;
      border-radius: 20px;
  
      &__icon {
        height: 20px;
      }
  
      &__txt {
        font-size: rem(14);
      }
    }

    &__thumbs {
      gap: 20px;
  
      img {
        width: calc((100% - 20px)/2);
      }
    }
  }
}