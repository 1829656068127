@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-day {
  background-image: url(../images/common/page-about-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    max-width: 700px;
    margin: 50px auto 0;
    padding: 0 20px;
  }
  &__box {
    width: 300px;
    position: relative;
   
  }
  &__box__time {
    font-size: rem(20);
    color: #ffffff;
    background-color: $green;
    padding: 2px 4px;
    display: block;
    width: 88px;
    text-align: center;
    border-radius: 4px;
    margin-top: 12px;
  }
  &__box__number {
  font-size: rem(32);
  font-weight: bold;
  background-image: url(../images/common/day-bg.svg);
  background-repeat: no-repeat;
  display: block;
  color: #ffffff;
  background-size: cover;
  width: 76px;
  height: 67px;
  line-height: 67px;
  left: -10px;
  text-align: center;
  }
  &__box__text {
margin-bottom: 30px;
  }
  &__box__main-image{
    border-radius: 30px;
    box-shadow: -16px -16px 0px 0px $yellow;
  }
  &__box__number {
    width: 76px;
    position: absolute;
    top: -30px;
    
  } 
}

@media screen and (max-width: 768px)
{
  .b-day__inner {
    display: block;
  }
  .b-day__box {
    margin: auto;
    width: 260px;
  }
  .b-day__box__number {
    font-size: 20px;
  }
  .b-day__box__time {
    font-weight: bold;
    font-size: 16px;
  }
}
