@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-fv {
display: flex ;
justify-content: space-between;
height: calc(100vh - 90px);
background: linear-gradient(180deg, #ffffff 0%, #ffffff 50%, $sub 50%, $sub 100%);
//height: 800px;

  &--page {
    height: 512px;
    background: none;
  }
}

.b-fv__movie-wrap {
  width: 84%;
  height: 100%;
  position: relative;
}
.b-fv__movie {
  border-top-left-radius: 130px;
  border-bottom-left-radius: 130px;
  position: relative;
  object-fit: cover;
  height: 100%;
  width:100%;
  filter: brightness(70%);
}
.b-fv__title-wrap {
  position: absolute;
  left: 4%;
  bottom: 10%;
}

.b-fv__title {
  font-family: 'Kiwi Maru', serif;
  color:$base ;
  font-size :rem(48);
  background-color: $white;
  padding: 8px 40px;
  border-radius: 30px;
  display: block;
  width: fit-content;

  &--second {
    margin: rem(16) auto 0;
  }
}

.b-fv__movie::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
}

.b-fv__side-bar {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin: 0 auto;
  height: 100%;
}
.b-fv__logo {
  display: block;
  width: rem(110);
  img {
    width: 100%;
  }
}

.b-fv__icon {
  display: block;
  width: rem(60);
  left: 27%;
  img {
    width: 100%;
  }
}

.b-fv__kv {
  position: relative;
  width: 84%;
  height: calc(100vh - 140px);
  padding: 0px 0px 8px;

  &__title {
    position: absolute;
    left: 4%;
    bottom: 10%;
  
    &__txt {
      font-family: 'Kiwi Maru', serif;
      color:$base ;
      font-size :rem(48);
      background-color: $white;
      padding: 8px 40px;
      border-radius: 30px;
      display: block;
      width: fit-content;
    
      &--second {
        margin: rem(16) auto 0;
      }
    }
  }

  &__img {
    display: block;
    height: 100%;
    border-top-left-radius: 130px;
    border-bottom-left-radius: 130px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  
    img {
      object-fit: cover;
      height: 100%;
      width:100%;
    }
  }
}

@media screen and (max-width: 767px){
  .b-fv {
    height: calc(100vh - 80px);
  
    &--page {
      height: 512px;
    }
  }
  .b-fv__sns {
    display: none;
  }
  .b-fv__movie-wrap {
    width: 90%;
    height: 100%;
  }
  
  .b-fv__movie {
    border-top-left-radius:0px;
    border-bottom-left-radius: 90px;
  }
  .b-fv__logo {
    width: rem(60);
    margin-top: -49px;
    margin-left: 20px;
  }

  .b-fv__title {
    font-size: rem(28);
    font-size: calc(28 / 375 * 100vw);
    padding: 4px 20px;
  }
  .b-fv__title--first {
    top: 46%;
  }
  .b-fv__title--second {
    top: 58%;
  }

  .b-fv__kv {
    width: 90%;

    &__title {
      &__txt {
        font-size: rem(28);
        font-size: calc(28 / 375 * 100vw);
        padding: 4px 20px;
      
        &--first {
          top: 46%;
        }
      
        &--second {
          top: 58%;
        }
      }
    }

    &__img {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 90px;
    }
  }
}