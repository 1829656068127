@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-page {
    padding: 0 rem(25);
}

.b-page__inner {
    max-width: rem(800);
    width: 100%;
    margin: 0 auto;
    padding: rem(100) 0 rem(100);
    position: relative;
}

.b-page__contents {
    h2 {
        display: block;
        background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
        width: 100%;
        border-radius: 8px;
        margin-bottom: 16px;
        margin-top: 16px;
        text-align: left;
    }

    h3 {
        display: block;
        border-bottom: 2px solid #2ab7b8;
        font-size: 18px;
        font-weight: bold;
        padding: 4px 20px;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: left;
    }

    h4 {
        display: block;
        border-bottom: 2px solid $sub;
        font-weight: bold;
        padding: 4px 20px;
        width: 100%;
        margin-bottom: 8px;
        margin-top: 8px;
        text-align: left;
    }

    a {
        color: #2ab7b8;
        cursor: pointer;
    }

    img {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 767px) {
    .b-page__inner {
        max-width: rem(600);
        padding: rem(80) 0 rem(80);
    }
}
