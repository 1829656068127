@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-btn-arrow {
    display: flex;
    margin-top: rem(48);
}

.b-btn-arrow__link {
    color: $base;
    transition: all 0.4s ease;
}

.b-btn-arrow__icon {
    position: relative;
    display: inline-block;
    background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 16px;
    vertical-align: middle;
    transition: all 0.4s ease;
}
.b-btn-arrow__icon::before {
    /* くの字の表示設定 */
    content: "";
    margin: auto;
    position: absolute;
    width: 16px; /* くの字を山なりに見た時、左側の長さ */
    height: 10px; /* くの字を山なりに見た時、右側の長さ */
    background: url(../images/common/arrow-white.png) no-repeat center center / contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease;
}

.b-btn-arrow__link:hover {
    opacity: 1;
    .b-btn-arrow__icon {
        background: #ffffff;
        border: solid 1px $base;
        transition: all 0.4s ease;
    }
    .b-btn-arrow__icon::before {
        background: url(../images/common/arrow-green.png) no-repeat center center / contain;
    }
}

@media screen and (max-width: 1024px) {
    .b-btn-arrow {
      margin-top: 30px;
    }
  
  }