@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

* {
  box-sizing: border-box;
}
body {
  font-family:'Avenir','Helvetica, Neue','Helvetica','Arial';
}


/* モーダルCSS */
.b-modal__Area {
  display: none;
  position: fixed;
  z-index: 10000; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.b-modal__Bg {
  width: 100%;
  height: 100%;
  background-color: rgba(30,30,30,0.9);
}

.b-modal__Wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
  width: 60%;
  max-width: 100%;
  padding: 10px 30px;
  background-color: #fff;
}

.closeModal {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
}


/* 以下ボタンスタイル */
button {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #282828;
  border-radius: 2px;
  cursor: pointer;
}

// #openModal {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform:translate(-50%,-50%);
// }

