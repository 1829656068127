@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-linkcodekids {
background-color: #EAF4F4;
border-radius: 295px 0 0 295px;
margin-left: 20%;
height: 448px;
&__inner{
display: flex;
align-self: center;
max-width: 1300px;
margin-top: 170px;
align-items: center;
justify-content: space-between;
height: 450px;

}

&__text-box {
width: 30%;
padding-left: 20px;
}
&__image-box {
display: flex;
align-self: center;
justify-content: space-between;
flex-wrap:wrap;
width: 40%;
// margin-left: -100px;
// margin-top: -50px;
z-index: 10;
}
&__illustration1{
  width: 20%;
  z-index: 10;
  align-self:flex-end;
  }
&__image1 {
  width: 100%;
  flex-grow: 2;
  border-radius: 50px;
  
}
&__image2 {
border-radius: 25px;
  width: 40%;
  
}
&__image3 {
  border-radius: 25px;
  width: 50%;
  height: 100%;
  align-self:flex-end;
}
}
// 楕円の飾り

.b-linkcodekids__text-box {
  position: relative;
}
.b-linkcodekids__text-box::after {
position: absolute;
content: '';
width: 138px;/*横幅*/
height: 153px;/*縦幅*/
background: $yellow;/*背景色*/
z-index: -10;
top: -70px;
right: -230px;
animation: fluidrotate 20s ease 0s infinite;/*アニメーションの設定*/
}
.b-linkcodekids__image-box {
  position: relative;
}
.b-linkcodekids__image-box::after {
position: absolute;
content: '';
width: 201px;/*横幅*/
height: 181px;/*縦幅*/
background: $yellow;/*背景色*/
z-index: -10;
bottom: -50px;
left: 30px;
animation: fluidrotate2 20s ease 0s infinite;/*アニメーションの設定*/
}
@keyframes fluidrotate {  
    
  0%, 100% {
      border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
      border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
      border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
      border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
      border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
      border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
      border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
      
  }

  @keyframes fluidrotate2 {  
      
      0%, 100% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
      }
      14% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
      }
      28% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
      }
      42% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
      }
      56% {
          
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
      }
      70% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
      }
      84% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
      }
          
      }
  @media screen and (max-width: 1024px) {
    .b-linkcodekids__inner {
        flex-wrap: wrap-reverse;
        margin-top: 50px;
        height: 100%;
        width: 90%;
        margin: auto;
        justify-content: center;
        padding-top: 50px;
    }
    
    .b-linkcodekids__image-box {
        margin: 0;
        width: 100%;
        padding-top: 30px;
    }
    .b-linkcodekids__text-box {
        width: 90%;
        padding: 0;
        order: 1;
    }
    .b-linkcodekids {
        margin-left: 0;
    }
    .b-linkcodekids__text-box::after {
        display: none;
    }
    .b-linkcodekids__image-box::after {
        display: none;
    }
    .b-linkcodekids {
        height: 100%;
        border-radius: 0 0 0 130px;
    margin-top: 50px;
    }

.b-linkcodekids__illustration1 {
    display: none;
}
.b-linkcodekids__image1 {
    border-radius: 10px;
}
.b-linkcodekids__image2 {
    border-radius: 10px;
}
.b-linkcodekids__image3 {
    border-radius: 10px;
}
}