@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;
.b-recruit {
  background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
  color: #ffffff;
  border-radius: 130px 0 0 0 ;
  margin-top: 80px;
  padding-bottom: 145px;
  padding-top: 56px;
}
.b-recruit__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  text-align: center;
  margin: auto;
}
.b-recruit__text {
border: dashed 2px #ffffff;/*点線*/
padding: 48px 180px;
flex-grow: 2;
border-radius: 50px;
}
.b-recruit__title {
  font-size: rem(40);
  font-weight: bold;
  background-image: none;
}
.b-recruit__subtitle {
  font-size: rem(16);
}
@media screen and (max-width: 1024px) {
  .b-recruit__inner {
    padding: 0 20px;
    max-width: 600px;
    width: 100%;
    margin: auto;
  }
  .b-recruit__text {
    //border:0;
  padding: 50px 20px;
  }
  .b-recruit__title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 0;
  }
}
