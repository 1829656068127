@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-footer {
  background-color: #EAF4F4;
  border-radius: 0 130px 0 0;
  margin-top: -120px;
  
}
.b-footer__nav__link a{
font-size: rem(16);
color: $base;
font-weight: bold;

}
.b-footer__nav__link {
  padding-top: 16px;
}

.b-footer__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  
  padding: 80px 20px 100px;
}
.b-footer__nav {
  display: flex;
  flex-direction:column;
  flex-wrap :wrap;
  width: 360px;
  height: 200px;
  text-align: center;
}
.b-footer__nav__link a{
  text-align: left;
}
.b-footer__logo-box {
  width: 100px;
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  align-items: center;
}

.b-footer__logo-box__logo {
  width: 100px;
  padding-bottom: 20px;
}
.b-footer__fb {
  width: 45px;
  display: inline-block;
}
.b-footer__insta {
  width: 45px;
  display: inline-block;
}
.b-footer__copy {
  color: #A9A9A9;
  text-align: center;
display: block;
padding-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .b-footer__inner {
    display: block;
  }
  .b-footer__logo-box {
    margin: auto;
  }
  .b-footer__nav {
margin: auto;
padding-top: 48px;
display: block;
  }
  .b-footer__nav__link {
    padding: 0 0 20px;
  }
  .b-footer__nav {
    width: 100%;
    height: 300px;
    align-content: space-between;
  }
  .b-recruit__bg-round1 {
    display: none;
  }
}
