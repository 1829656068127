/* ------------------------------ */
/* Front Features */
/* ------------------------------ */
@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-front-features {
  position: relative;
  display: flex;
  align-items: center;
  gap: 80px;
  padding: 100px 40px;
  width: 1020px;
  max-width: 100%;
  margin: 160px auto;

  &__contents {
    &__title {
      margin: 0px 0px 30px;

      &__main {
        display: block;
        font-size: rem(36);
        font-weight: 700;
        letter-spacing: 0.1em;
        color: $base;
        margin: 0px 0px 8px;
      
        &::after {
          content: '';
          display: block;
          width: 36px;
          height: 2px;
          margin: 5px 0px 0px;
          background-color: $base;
        }
      }
    
      &__sub {
        font-size: rem(16);
        font-weight: 100;
        color: $base;
      }
    }
  
    &__txt {
      line-height: 1.8;
    }
  }

  &__img {
    flex-shrink: 0;
    width: 35%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 64px);
    display: block;
    width: calc( 100vw - 64px );
    height: 100%;
    background-color: $sub;
    z-index: -1;
    transform: translateX(-50%);
    border-top-right-radius: 130px;
    border-bottom-right-radius: 130px;
  }

  @media screen and (max-width: 1023px ) {
    flex-direction: column;
    gap: 40px;
    margin: 60px auto;
    padding: 60px 32px;
    width: 50rem;

    &__img {
      width: 320px;
      max-width: 100%;
    }

    &::before {
      left: 50%;
      width: 100vw;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 130px;
    }
  }
}