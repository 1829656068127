@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-requirement__wrap {
  max-width: 82%;
  margin-left: auto;
  margin-top: 80px;
  padding: 0 20px;
&__title {
  display: inline-block;
  width: 100%;
   font-size: rem(24);
   font-weight: bold;
   background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
   color: #fff;
   height: rem(80);
   line-height: rem(80);
   padding-left: rem(50);
   border-radius: 15px;

  }
}
.b-requirement__box {
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding: 0 20px 24px;
  border-bottom: 1px dotted #EAF4F4;

  &__title {
    font-size: rem(20);
    width: 30%;
    display: inline-block;
  }
  &__text {
    font-size: rem(16);
    display: inline-block;
  }
}
.b-requirement {
  margin-bottom: 150px;
}
@media screen and (max-width: 1024px) {
.b-requirement__box {
  display: block;
}
.b-requirement__wrap {
  width: 100%;
}
.b-requirement__wrap {
  max-width: 100%;
}
.b-requirement__box__text {
  padding-top: 10px;
}
.b-requirement__wrap__title {
  height: 4rem;
  line-height: 4rem;
  font-size: rem(18);
  padding-left: 20px;
}
}
.b-requirement__box__title__norecruitment {
  width: 100%;
  padding-top: 30px;
  padding-left: 20px;
  
}