@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

/* zoomIn  */

.zoomIn{
  animation-name:zoomInAnime;
  animation-duration:3s;
  animation-fill-mode:forwards;
  opacity: 1;
}


  @keyframes zoomInAnime{
    from {
      opacity: 1;
      transform: scale(0.3);
      }
    
      to {
        opacity: 1;
          transform: scale(1);
      }
    }
    



  /* スクロールをしたら出現する要素にはじめに透過0を指定　*/
   
  .zoomInTrigger{
      opacity: 0;
      
  }
  
  