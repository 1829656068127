@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-title-box-g{
  color: $base;
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.b-title-box-g__bg {
  width: 97px;
  position:absolute;
  left: 50%;
  top: 20%;
  transform: translateY(-50%) translateX(-50%);
  z-index: -1;
}
.b-title-box-g__title {
  font-size:rem(36);
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  .b-title-box-g__title {
    font-size: rem(26);
  }
  
}
