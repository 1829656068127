@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;


//animate.cssの調整
@-webkit-keyframes bounceInUpSmall {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 400px, 0) scaleY(1);
      transform: translate3d(0, 400px, 0) scaleY(1);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
      transform: translate3d(0, -20px, 0) scaleY(0.9);
    }
  
    75% {
      -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
      transform: translate3d(0, 10px, 0) scaleY(0.95);
    }
  
    90% {
      -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
      transform: translate3d(0, -5px, 0) scaleY(0.985);
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes bounceInUpSmall {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 400px, 0) scaleY(1);
      transform: translate3d(0, 400px, 0) scaleY(1);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
      transform: translate3d(0, -20px, 0) scaleY(0.9);
    }
  
    75% {
      -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
      transform: translate3d(0, 10px, 0) scaleY(0.95);
    }
  
    90% {
      -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
      transform: translate3d(0, -5px, 0) scaleY(0.985);
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .animate__bounceInUpSmall {
    -webkit-animation-name: bounceInUpSmall;
    animation-name: bounceInUpSmall;
  }





@-webkit-keyframes fadeInUpSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 50%, 0);
      transform: translate3d(0, 50%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInUpSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 50%, 0);
      transform: translate3d(0, 50%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .animate__fadeInUpSmall {
    -webkit-animation-name: fadeInUpSmall;
    animation-name: fadeInUpSmall;
  }