@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-btn-arrow.b-btn-arrow--white {
    margin-top: 24px;
  display: flex;
  //margin-right: 20px;
    .b-btn-arrow__link {
        color: $white;
    }
    .b-btn-arrow__link.b-btn-arrow__base {
        color: $base;
    }

    .b-btn-arrow__icon {
        background: #ffffff;
        border: 1px solid $base;
        transition: all 0.2s ease;
    }

    .b-btn-arrow__icon::before {
        background: url(../images/common/arrow-green.png) no-repeat center center / contain;
    }
    
    .b-btn-arrow__link:hover {
        .b-btn-arrow__icon {
            background: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
            border: solid 2px $white;
        }
        .b-btn-arrow__icon::before {
            background: url(../images/common/arrow-white.png) no-repeat center center / contain;
        }
    }

}

@media screen and (max-width: 1024px) {
    .b-btn-arrow.b-btn-arrow--white {
        margin-top: 16px;
    }
    }