@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-page-about {
  &__fv {
    background: #ffffff;
    height: 547px;
  }
  &__about {
    background-color: #ffffff;
  }
  &__fv__Box {
    position: relative;
  }
  &__fv__Box-title {
    display: inline-block;
    font-size: rem(40);
    color: $base;
    padding: 12px 10px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translateY(-50%);
    //transform: translate(-50%, -40%);
    letter-spacing: 0.1em;
    min-width: 330px;
    text-align: center;
  }
  &__fv__Box-title::after {
    content: "";
    position: absolute;
    background: url(../images/common/title-bg.png) no-repeat center center / contain;
    width: 120%;
    height: 120%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -10;
  }
  /*
&__fv__Box-title::before {
content: '';
position: absolute;
background-image: url(../images/common/title-bg.png);
width: 330px;
top: 11%;
left: 0%;
height: 80%;
background-repeat:  no-repeat;                         /* 画像の繰り返しを指定  */
  //background-position:center center;                     /* 画像の表示位置を指定  */
  //background-size:contain;                               /* 画像のサイズを指定    */
  //z-index: -10;
  //}
}

.b-page-about__secondary {
  margin-top: 100px;
  padding-bottom: 80px;

  &__inner {
    position: relative;
  }
  &__inner::before {
    position: absolute;
    content: "";
    background-color: #eaf4f4;
    border-radius: 0 130px 130px 0;
    width: 97%;
    height: 100%;
    z-index: -10;
    padding-bottom: 100px;
  }
}

.b-page-about__secondary__box {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 100px 20px 0;
  &__image {
    width: rem(480);
    margin-right: 4%;
  }
  &__image img {
    border-radius: 60px;
    width: 100%;
  }
  &__text {
    width: 30%;
    line-height: 1.8;
  }
}


@media screen and (max-width: 800px) {
  .b-page-about__fv__Box-title {
    left: 25%;
  }
}
@media screen and (max-width: 600px) {
  .b-page-about__fv__Box-title {
    left: 15%;
    font-size: rem(24);
    min-width: calc(240 / 375 *100vw);
  }
}

@media screen and (max-width: 767px) {
  .b-page-about__secondary__box {
    flex-wrap: wrap-reverse;
    padding-top: 50px;
    max-width: rem(600);
    width: 100%;
    margin: auto;
  }
  .b-page-about__secondary__box__image {
    margin-right: 0;
    width: 100%;
    margin-top: 20px;
  }
  .b-page-about__secondary__box__text {
    width: 100%;
  }
  .b-page-about__secondary__inner::before {
    border-radius: 0 0 130px 0;
    width: 100%;
  }

  .b-page-about__secondary__box__image.u-ml__5 {
    margin-left: 0;
  }
}

 