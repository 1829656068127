@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-about {
  background-color: $sub;
  padding-top: 40px;
  border-radius: 0 0 0 130px;
  //z-index: -10;
  position: relative;

  &__inner {
    height: 550px;
  }
  &__text-box {
    position: relative;
    z-index: 10;
  }
  &__img {
    z-index: 10;
  }
}

// 楕円の飾り
.b-about__img {
  position: relative;
}
.b-about__img::before {
position: absolute;
content: '';
width: 132px;/*横幅*/
height: 152px;/*縦幅*/
background: $yellow;/*背景色*/
top: -100px;
right:-10px;
animation: fluidrotate 20s ease 0s infinite;/*アニメーションの設定*/
}

.b-about__img::after {
  position: absolute;
content: '';
width: 281px;/*横幅*/
height: 313px;/*縦幅*/
background: $green;/*背景色*/
bottom: -180px;
right:-10px;
animation: fluidrotate2 20s ease 0s infinite;/*アニメーションの設定*/
z-index: -10;
}

@keyframes fluidrotate {  
    
  0%, 100% {
      border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
      border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
      border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
      border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
      border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
      border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
      border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
      
  }

  @keyframes fluidrotate2 {  
      
      0%, 100% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
      }
      14% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
      }
      28% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
      }
      42% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
      }
      56% {
          
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
      }
      70% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
      }
      84% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
      }
          
      }








// 背景のロゴ
.b-about__text-box::after{
  content: "";
  width: 513px;
  background: url(../images/common/Group.png)no-repeat center center;
  background-size: contain;
  height: 420px;
  position: absolute;
  left: 0px;
  top: 0px;
z-index: -10;
}
@media screen and (max-width: 1024px) {
  .b-about {
    height: 800px;
    border-radius: 0 0 130px 0;
  }
  .b-about__img::before {
    width: 80px;/*横幅*/
height: 100px;/*縦幅*/
top: -30px;
  }
  .b-about__img::after {
   
    width: 150px;/*横幅*/
    height: 130px;/*縦幅*/
   left: 10px;
   bottom: -90px;
  }
  .b-about__text-box {
    margin-bottom: 50px;
   
  }
  .b-about__img {
    //width: 500px;
  }
  
  .b-about__text-box::after {
    width: 100%;
  }
}

