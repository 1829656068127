/* ------------------------------ */
/* Sub Section */
/* ------------------------------ */
@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-sub-section {
  display: flex;
  align-items: center;
  gap: 40px;

  &__contents {
    flex-shrink: 0;
    width: 64%;
  
    &__title {
      color: $base;
      margin: 0px 0px 20px;

      &__main {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin: 0px 0px 5px;
        border-bottom: 1px solid $base;

        &__num {
          font-size: rem(34);
        }
      
        &__txt {
          font-size: rem(28);
        }
      }
    
      &__sub {
        font-size: rem(14);
      }
    }
  
    &__txt {
      font-size: rem(16);
      line-height: 1.8;
    }
  }

  &__img {
    width: 100%;
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  &:not(:last-child) {
    margin: 0px 0px 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

    &:nth-child(odd) {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin: 0px 0px 30px;
    }

    &__contents {
      width: 100%;
    
      &__title {
        &__main {
          &__num {
            font-size: rem(24);
          }
        
          &__txt {
            font-size: rem(20);
          }
        }
      
        &__sub {
          font-size: rem(12);
        }
      }
    }
  
    &__img {
      width: 60%;
    }
  }
}