@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;
.b-header {
  height: 90px;
  line-height: 90px;
}

.b-header__nav__inner {
  display: flex;
  justify-content: flex-end;
  font-size: rem(15);
  height: 90px;
  align-items: center;
  font-weight: bold;
}
.b-header__link {
  margin-right: 32px;
  height: inherit;
}
.b-header__link a {
  display: block;
  height: inherit;
  line-height: 90px;
}
.b-header-sp {
  display: none;
}


@media screen and (max-width: 767px) {

  .b-header {
    display: none;
  }
  .b-header-sp {
    height: 80px;
    line-height: 90px;
    background: #ffffff;
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
  }

  .b-fv__logo__img {
    width: 60px;
  }
}
.menu-btn {
  position: relative;
  top: 10px;
  right: 10px;
  display: flex;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: $base;
  border-radius: 10px;
  margin-left: auto;
}
.menu-btn span,
.menu-btn span:before,
.menu-btn span:after {
  content: "";
  display: block;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background-color: #ffffff;
  position: absolute;
}
.menu-btn span:before {
  bottom: 8px;
}
.menu-btn span:after {
  top: 8px;
}

#menu-btn-check:checked ~ .menu-btn span {
  background-color: rgba(255, 255, 255, 0); /*メニューオープン時は真ん中の線を透明にする*/
} 
#menu-btn-check:checked ~ .menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}
#menu-btn-check:checked ~ .menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}

.menu-content__inner {
  padding: 80px 10px;
}
.menu-content__list {
  border-bottom: solid 1px #ffffff;
  list-style: none;
}
.menu-content__list a {
  display: block;
  width: 100%;
  font-size: 15px;
  box-sizing: border-box;
  color: #ffffff;
  text-decoration: none;
  padding: 9px 15px 10px 0;
  position: relative;
  font-weight: bold;
}

.menu-content {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 100%; /*leftの値を変更してメニューを画面外へ*/
  z-index: 80;
  background-color: $base;
  transition: all 0.5s; /*アニメーション設定*/
}
#menu-btn-check:checked ~ .menu-content {
  left: 0; /*メニューを画面内へ*/
}
