@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-page-section {
  position: relative;
}

.b-page-section__inner::before {
  content: none;
  }

.b-page-section::after {
position: absolute;
content: '';
background-color: #EAF4F4;
width: 100%;
height: 100%;
z-index: -10;
top: 0px;
height: 420px;
left: 0;
border-radius: 0 0 100px 0;
}
.b-page-section {
  &__box {
    color: #333333;
  }
}