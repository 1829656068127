@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

/* fadeUp */

.fadeUp{
  animation-name:fadeUpAnime;
  animation-duration:3s;
  animation-fill-mode:forwards;
  opacity:0;
  }



  @keyframes fadeUpAnime{
    from {
      opacity: 0;
    transform: translateY(100px);
    }
  
    to {
      opacity: 1;
    transform: translateY(0);
    }
  }
  /* 下から */


  /* fadeUpをするアイコンの動き */

.fadeUp2{
  animation-name: fadeUpAnime;
  animation-duration:0.5s;
  animation-fill-mode:forwards;
  opacity: 0;
  display: block;
  }
  
  @keyframes fadeUpAnime{
  from {
    opacity: 0;
  transform: translateY(100px);
  }
  
  to {
    opacity: 1;
  transform: translateY(0);
  }
  }
  
  
  
  



  /* スクロールをしたら出現する要素にはじめに透過0を指定　*/
   
  .fadeUpTrigger{
      opacity: 0;
      
  }
  
  // * アニメーションスタートの遅延時間を決めるCSS*/

.delay-time02{
animation-delay: 0.2s;
}

.delay-time04{
animation-delay: 0.4s;
}
  
.delay-time06{
  animation-delay: 0.6s;
  }
 .delay-time08 {
  animation-delay: 0.8s;
 }   