@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-fv-second {
  background-color: $sub;
  // height: rem(300);
  padding: rem(20);
&__ring-l {
  width: rem(200);
}
&__ring-r {
  width: rem(256);
}
&__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: rem(1220);
  margin: auto;
}
&__title {
  background-image: url(../images/common/page-title-bg.png);
  color: $base;
  font-size: rem(48);
  background-repeat: no-repeat;
background-position: center;
width: 330px;
text-align: center;
padding: 12px 0px;

}
&__box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;

}
&__subtitle {
  font-size: rem(15);

  color: $base;

}

}
.b-fv-second__title {
  font-size: 22px;
}
@media screen and (max-width:768px) {
  .b-fv-second {
    height: auto;
  }
  .b-fv-second__inner img {
    min-width: 0; 
  }
  .b-fv-second__subtitle {
    min-width: 0;
  }
  
}