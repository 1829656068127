@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;
.b-linkarea {
  max-width: 700px;
  color: $base;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
    justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 30px 20px 50px;
}

.b-linkarea__link {
  width: 100px;
  height: 100px;
  font-size: rem(14);
  border-radius: 50%;
  border: 2px dotted $base;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.b-linkarea__link a {
  display: block;
  width: 100%;
  opacity: 1;
}
.b-linkarea__link a::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

.b-linkarea__link:hover {
  background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
  color: #ffffff;
  transition: all 0.5s linear;
  border: 2px dotted #ffffff;
}
.b-linkarea__link:hover {
  transform: scale(1.1, 1.1);
  transition: 0.5s all;
}
@media screen and (max-width: 767px) {
  .b-linkarea {
    padding: 20px 10px 20px;
  }
}
