@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-section {
  &__inner {
    display: flex;
    //max-width: 82%;
    max-width: rem(1100);
    width: 100%;
    margin: 0 auto;
    padding-left: 32px;
    padding-right: 32px;
    align-items: center; 
    
    &--column {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 1004px;
    }
  }

  &__title-box {
    margin-bottom: 24px;
    color: $base;
  }

  &__text-box{
    margin-right: 20px;
    z-index: 10;
    color: $base;
    width: 70%;
    line-height: 1.75;
  }

  &__img img {
    border-radius: 50px;
  } 

  &__title-box__title{
    font-size:rem(36);
    color: $base;
    display: block;
  }

  &__title-box__subtitle {
    margin-top: 16px;
  }
  &__text-box-text {
    margin-top: 24px;
    color: #333333;
    
  }

  &__title {
    font-size: rem(36);
    font-weight: 700;
    color: $base;
    margin: 0px 0px 20px;
    word-break: break-all;
  
    &::after {
      content: '';
      display: block;
      width: 36px;
      height: 2px;
      margin: 5px 0px 0px;
      background-color: $base;
    }
  }

  &__title-with-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto 40px;
  
    &__main {
      font-size: rem(36);
      font-weight: 700;
      color: $base;
      letter-spacing: 0.1em;
    }
  
    &__sub {
      font-size: rem(14);
      color: $base;
    }
  
    &::before {
      content: '';
      display: block;
      width: 48px;
      height: 40px;
      background-image: url('../images/common/icon-linkids.svg');
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  &__lead {
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.8;
    margin: 0px 0px 30px;
  }

  &__txt {
    font-size: rem(16);
    line-height: 1.8;
  }

  &--bg-blue,
  &--bg-light-blue {
    position: relative;
    padding: 100px 0px;
  
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      display: block;
      height: 100%;
      width: calc(100% - 64px);
      z-index: -1;
    }
  }

  &--bg-blue {
    &::before {
      right: 0px;
      background-color: $green;
      border-top-left-radius: 130px;
      border-bottom-left-radius: 130px;
    }
  
    .b-section__title {
      color: $white;
    
      &::after {
        background-color: $white;
      }
    }
  }

  &--bg-light-blue {
    &::before {
      left: 0px;
      background-color: $sub;
      border-top-right-radius: 130px;
      border-bottom-right-radius: 130px;
    }
  }
  
  &--with-watermark {
    position: relative;
  
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      right: 100px;
      width: 502px;
      height: 400px;
      background-image: url('../images/common/watermark-logo.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      z-index: -1;
    }
  
  }
  
  @media screen and (max-width:1024px) {
    &--bg-light-blue,
    &--bg-blue {
      padding: 60px 0px;
  
      &::before {
        width: 100%;
        border-radius: 0px;
      }
    }
  
    &--with-watermark {
      &::after {
        right: initial;
        left: 50%;
        width: 300px;
        transform: translateX(-50%);
      }
    }
  }
  
  @media screen and (max-width:768px) {
    &__title {
      font-size: rem(30);
    }
  
    &__title-with-icon {
      margin: 0px auto 24px;
  
      &__main {
        font-size: rem(26);
      }
  
      &__sub {
        font-size: rem(16);
      }
  
      &::before {
        width: 48px;
        height: 48px;
      }
    }
  
    &__lead {
      font-size: rem(18);
    }
  }
}

.b-section__title-box__title {
  position: relative;
  width:100%;
	height:60px;
}
.b-section__title-box__title::after{
  content: '';
  width: 56px;
  height: 3px;
  position: absolute;
  bottom: -8px;
left: 30px;
transform: translateY(-50%) translateX(-50%);
  background-color: $base;
  }
  
  .b-section__title-box__title__1{ 
     
    animation: bound-anim 0.8s 0.0s infinite;
    position: absolute;

  }
  .b-section__title-box__title__2{ 
    left: 1.4em;
    animation: bound-anim 0.8s 0.1s infinite;
    position: absolute;
  }
  .b-section__title-box__title__3{	
    left: 2.8em;
    animation: bound-anim 0.8s 0.2s infinite;
    position: absolute;
  }
  .b-section__title-box__title__4{	
    left: 4.2em;
    animation: bound-anim 0.8s 0.3s infinite;
    position: absolute;
  }
  .b-section__title-box__title__5{	
    left: 5.6em;
    animation: bound-anim 0.8s 0.4s infinite;
    position: absolute;
  }
  @keyframes bound-anim {
    0%,100% {top: 0;transform: scale(1);}
    30% {top: -25%;}
    50% {transform: scale(1);}
    90% {top: 0;transform: scale(1.2,0.8);}
  }

// 楕円の飾り
.b-section__messege-img {
  position: relative;
}
.b-section__messege-img::after {
  position: absolute;
  content: "";
  width: 172px; /*横幅*/
  height: 192px; /*縦幅*/
  background: $green; /*背景色*/
  z-index: -10;
  bottom: -70px;
  right: -30px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}

.b-section__business-img {
  position: relative;
}
.b-section__business-img::after {
  position: absolute;
  content: "";
  width: 172px; /*横幅*/
  height: 192px; /*縦幅*/
  background: $yellow; /*背景色*/
  top: -30px;
  left: -100px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}
.b-section__environment-img {
  position: relative;
}
.b-section__environment-img::before {
  position: absolute;
  content: "";
  width: 192px; /*横幅*/
  height: 172px; /*縦幅*/
  background: $green; /*背景色*/
  z-index: 10;
  bottom: -70px;
  right: -30px;
  animation: fluidrotate 20s ease 0s infinite; /*アニメーションの設定*/
}
.b-section__philosophy-img {
  position: relative;
}
.b-section__philosophy-img::before {
  position: absolute;
  content: "";
  width: 142px; /*横幅*/
  height: 128px; /*縦幅*/
  background: $green; /*背景色*/
  z-index: 0;
  bottom: -90px;
  left: -10px;
  animation: fluidrotate2 20s ease 0s infinite; /*アニメーションの設定*/
}
.b-section__philosophy-img::after {
  position: absolute;
  content: "";
  width: 192px; /*横幅*/
  height: 172px; /*縦幅*/
  background: $yellow; /*背景色*/
  z-index: 10;
  bottom: -50px;
  left: -100px;
  animation: fluidrotate 30s ease 0s infinite; /*アニメーションの設定*/
}

@keyframes fluidrotate {
  0%,
  100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}

@keyframes fluidrotate2 {
  0%,
  100% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  42% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  56% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  70% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  84% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
}



  @media screen and (max-width: 1024px) {
    .b-section__inner {
      flex-wrap :wrap;
      max-width: rem(800);
      width: 100%;
    }
    .b-section__img {
      margin: auto;
    }
    .b-section__img img {
      border-radius: 30px;
    }
.b-section__text-box {
  width: 100%;
  margin-right: 0;
}
.b-section__title-box__title {
  font-size: 30px;
}
  }

  @media screen and (max-width: 767px) {
    .b-section__environment-img::before {
      display: none;
    }
    .b-section__business-img::after {
      display: none;
    }
    .b-section__philosophy-img::before {
      display: none;
    }
    .b-section__philosophy-img::after {
      display: none;
    }
  }
