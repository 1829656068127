@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;


.b-contact {
margin-top: 40px;
//width: 82%;
margin-left: auto;
margin-right: auto;
  &__inner {
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    
    
  }
  &__text {
    text-align: center;
    font-size: rem(20);
  }
}
.b-contact__header::after {
background-color: #fff;
}

/*ContactForm7カスタマイズ*/
// .input-box {
//   // height: 70px;
// }
#cf-tbl{
  // width: 800px;
  margin-top: 50px;
  }
  
  #cf-tbl table{
  width: 100%;
  border-collapse: collapse;
  
  border-width: 1px;
  
  }
  #cf-tbl table tr th,
  #cf-tbl table tr td{
  padding: 0.5em;
  text-align: left;
  vertical-align: top;
  
  border-width: 1px;
  vertical-align: middle;
  }
  #cf-tbl table tr th{
  width: 35%;
  
  }
  
  @media screen and (max-width:768px){
  #cf-tbl{
  width: 100%;
  }
  
  #cf-tbl table,
  #cf-tbl table tbody,
  #cf-tbl table tr,
  #cf-tbl table tr th,
  #cf-tbl table tr td{
  display: block;
  }
  
  #cf-tbl table{
  width: 100%;
  border-width: 0 0 1px 0;
  }
  
  #cf-tbl table tr th{
  width: 100%;
  padding: 2% 5% 1%;
  }
  #cf-tbl table tr td {
  width: 100%;
  padding: 1% 5% 2%;
  }
  
  #cf-tbl table tr td{
  border-width: 0px 1px 0px 1px;
  }
  }
  /*「必須」文字デザイン*/
  .required{
  font-size:.8em;
  padding: 5px 8px;
  background: #EA5550;
  color: #fff;
  border-radius: 10px;
  margin-right: 5px;
  }
  
  
  
  /* 入力項目を見やすく */
  input.wpcf7-form-control.wpcf7-text,
  textarea.wpcf7-form-control.wpcf7-textarea {
    width: 100%;
    padding: 8px 15px;
    margin-right: 10px;
    //margin-top: 10px;
    
    border-radius: 3px;
    background-color: #EAF4F4;
  }
  textarea.wpcf7-form-control.wpcf7-textarea {
    height: 200px;
  }
  
  /* 「送信する」ボタン */
  input.wpcf7-submit {
      display: block;
      padding: 15px;
      width: 400px;
      background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      border-radius: 2px;
      margin: 15px auto 0;
      border-radius: 30px;
      transition: all .4s ease;
  }
  @media screen and (max-width:768px){
  input.wpcf7-submit {
  width: 250px;
  }
  }
  @media screen and (max-width:1024px){
  
    .b-contact {
      width: 100%;
    }
  
    }
  input.wpcf7-submit:hover {
    box-shadow: 0 15px 30px -5px rgba(0,0,0,.15), 0 0 5px rgba(0,0,0,.1);
    transform: translateY(-4px);
    opacity:0.7;
  }
  /* エラーメッセージを見やすく */
  span.wpcf7-not-valid-tip,
  .wpcf7-response-output.wpcf7-validation-errors {
    color: red;
    font-weight: 600;
  }
  .checkbox-color {
    color:$base;
    // background-color: #EAF4F4;
    text-align: center;
  }
/* チェックボタンカスタマイズ*/
.wpcf7-form-control-wrap {
  display: block;
  text-align: center;
}
span.wpcf7-list-item {
  margin: 0 30px 0 0; /* 項目右側の余白設定と、デフォルトの左側の余白を打ち消す */
  position: relative;
}
.wpcf7-list-item-label {
  cursor: pointer; /* labelにhoverした時にカーソルを表示させる */
  font-size: 16px; /* 項目のフォントサイズ */
}
input[type="checkbox"] {
  opacity: 0; /* デフォルトのチェックボックスを見えなくする */
  position: absolute;
}
.wpcf7-acceptance a {
  border-bottom: #333 1px solid; 
}
.wpcf7-list-item-label::before {
  /* チェックボックスのデザイン */
  border: 1px solid #333;
  content: "";
  display: inline-block;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 15px;
}
input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
  /* チェックアイコン */
  background-image: url(../images/common/check.png) ;
  
  background-size: contain;
  content: "";
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 15px;
  height: 15px;
}
input[type="checkbox"]:checked + .wpcf7-list-item-label::before {
  /* チェックしたボックスの背景色を設定 */
  background-color: #EAF4F4;
}
.dropmenu {
  width: 100%;
    padding: 8px 15px;
    margin-right: 10px;
    //margin-top: 10px;
    border-radius: 3px;
    background-color: #EAF4F4;
    
}


//お問い合わせ完了（サンクスページ）のスタイル
.b-contact__bottom {
  max-width: rem(1000);
  width: 100%;
  margin: rem(100) auto 0;
}

//お問い合わせ完了ページのお知らせ一覧のスタイル
.b-contact__card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: rem(1000);
  width: 100%;
  margin: 0 auto;
}

.b-contact__card {
  max-width: calc(25% - rem(32) * 3 / 4);
  width: 100%;
}
.b-contact__card:not(:first-child) {
  margin-left: rem(32);
}
//hover時のアニメーション
.b-contact__card:hover {
  .b-contact__card__img img{
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.b-contact__card__img {
  text-align: center;
  padding-top: 66.666%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.b-contact__card__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease;
}
.b-contact__card__tag {
  position: absolute;
  display: inline-block;
  background-color: $base;
  border-radius: 30px;
  color:#ffffff;
  font-size: rem(12);
  font-weight: bold;
  padding: 6px 16px;
  top:10%;
  left: 5%;
  z-index: 1000;

  a {
    position: relative;
    z-index: 1000;
  }
}
//カードの中身
.b-contact__card__text-box{
  padding: rem(12) rem(4);
  min-height: rem(100);
  display: flex;
  flex-direction: column;
}
.b-contact__card__day {
  font-size: rem(10);
}
.b-contact__card__title {
  margin-top: 16px;
  font-weight: 500;
  font-size: rem(14);
  color: $base;
}

@media screen and (max-width: 767px) {
  .b-contact__card-wrap {
    max-width: rem(600);
  }
  .b-contact__card {
    max-width: calc(50% - rem(24) * 1 / 2);
  }
  .b-contact__card:not(:first-child) {
    margin-left: rem(24);
  }
  .b-contact__card:nth-child(2n + 1) {
    margin-left: 0;
  }
  .b-contact__card:nth-child(n + 3) {
    margin-top: rem(32);
  }
  
  .b-contact__card__tag {
    font-size: rem(8);
    font-weight: bold;
    padding: 3px 8px;
  }
  .b-contact__card__text-box{
    min-height: rem(70);

  }
}

  
