/* ------------------------------ */
/* Features Work List */
/* ------------------------------ */
@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-feature-work-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  width: 100%;

  &__item {
    width: calc((100% - 40px) / 3);

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: $white;
      padding: 20px 18px;
      border-radius: 14px;
    }

    &__thumb {
      position: relative;
      margin: 0px 0px 10px;

      &__img {
        display: block;
        width: 100%;
        height: 190px;
        object-fit: contain;
        margin: 0px auto;
      }
    
      &__label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: rem(12);
        font-weight: 700;
        color: $white;
        padding: 0px 20px;
        border-radius: 15px;
        background-color: $base;
      
        &::before {
          content: '';
          width: 18px;
          height: 18px;
          background-image: url('../images/common/icon-sp.svg');
        }
      }
    }

    &__contents {
      &__title {
        font-size: rem(14);
        font-weight: 700;
        margin: 0px 0px 20px;
      }

      &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin: 0px 0px 10px;
      
        &__item {
          display: flex;
          align-items: center;
          height: 36px;
          max-width: 100%;
          font-size: rem(12);
          font-weight: 700;
          color: $base;
          padding: 0px 10px;
          border: 1px solid $base;
          border-radius: 18px;

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    
    }

    &__btn {
      margin: auto 0px 0px;
    }

    &:first-child {
      width: 100%;
    
      a {
        width: 600px;  
        max-width: 100%;
        margin: 0 auto;
      }
    
      .b-feature-work-list__item {
        &__thumb {
          &__img {
            width: 90%;
            height: auto;
          }

          &__label {
            height: 40px;
            font-size: rem(14);
            border-radius: 20px;
          }
        }
      
        &__contents {
          &__title {
            font-size: rem(18);
          }
      
          &__tags {
            gap: 10px;
        
            &__item {
              height: 40px;
              font-size: rem(14);
              border-radius: 20px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    gap: 20px 0px;

    &__item {
      width: 100%;
    
      a,
      &:first-child a {
        .b-feature-work-list__item {
          &__thumb {
            &__img {
              height: 200px;
            }
          
            &__label {
              height: 30px;
              font-size: rem(14);
              border-radius: 15px;
            }
          }
        
          &__contents {
            &__title {
              font-size: rem(14);
            }
        
            &__tags {
              gap: 5px;
            
              &__item {
                height: 36px;
                font-size: rem(10);
              }
            }
          }
        }
      }
    }
  }
}