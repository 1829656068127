@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-news {
margin-top: 70px;
padding-top: 100px;
position: relative;
&__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 220px;
  padding:0 20px;
  max-width: 1300px;
  margin: 220px auto 0;
}
&__box{
  margin-bottom: 48px;
  width: 25%;
}
&__box__day {
  font-size: rem(12);
}
&__box__textbox{
  margin-top: 16px;
}
&__title {
  margin-top: 16px;
}
&__box__textbox{
  margin-top: 16px;
}
&__box__img img {
  border-radius: 15px;
  display: inline-block;
}
&__box__img {
  position: relative;
}
&__illustration-box{
  width: 300px;
  z-index: 10;
  margin-left: 60px;
top: 72px;
position: absolute;
  }

}
.b-news__box__img:hover{
  transform:scale(1.1,1.1);
  transition:0.5s all;
}
.b-news__box__img {
  height: 200px;
}
.b-news__box__img img {
  object-fit: cover;
  height: 100%;
}
// 楕円の飾り
.b-news::before{
position: absolute;
content: '';
background-color: #EAF4F4;
border-radius: 0 130px 130px 0;
width: 90%;
height: 100%;
z-index: -10;
}
// .b-news__inner::after {
// position: absolute;
// content: '';
// bottom: -280px;
// left: 20px;
// width: 300px;
// height: 209px;
// background-image: url(../../images/common/illustration2.png);
// display: inline-block;
// background-size: cover;
// }
.b-news__inner::before {
position: absolute;
content: '';
width: 242px;/*横幅*/
height: 218px;/*縦幅*/
background: $yellow;/*背景色*/
z-index: -10;
bottom: -280px;
left: 20px;
animation: fluidrotate 20s ease 0s infinite;/*アニメーションの設定*/
}
@keyframes fluidrotate {  
    
  0%, 100% {
      border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
      border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
      border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
      border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
      border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
      border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
      border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
      
  }


// タグ

.b-news__box__tag {
  position: absolute;
  display: inline-block;
  background-color: $base;
  border-radius: 30px;
  color:#ffffff;
  font-size: rem(12);
  font-weight: bold;
  padding: 6px 16px;
  top:10%;
  left: 5%;
}

@media screen and (max-width: 768px) {
.b-news__inner {
  display: block;
  margin-top: 0;
  padding: 190px 20px 0;
}
.b-news__box {
  width: 100%;
}
.b-news {
  margin-top: 50px;
  padding-top: 0;

}
.b-news::before {
  width: 100%;
  border-radius: 130px 0 0 0;
}
.b-news__inner::before {
  bottom: -200px;
  width: 192px;
    height: 168px;
}
}

