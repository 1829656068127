@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-single-main {
  background-color: $sub;
  padding-bottom: rem(200);
  padding-left: rem(20);
  padding-right: rem(20);
  &__inner {
    max-width: rem(1180);
    background-color: #fff;
    border-radius: 30px;
    margin: auto;
    padding: rem(56) rem(95);
  }
  &__fv {
max-width: rem(970);
margin: auto;
  }
&__box {
margin-top: rem(32);
&__title  {
  display: block;
  background-image: linear-gradient(90deg, rgba(18, 215, 179, 1) 40%, rgba(42, 183, 184, 1));
  color: #fff;
  font-size: rem(20);
  font-weight: bold;
padding: rem(10) 0;
width: rem(213);
text-align: center;
border-radius: rem(8);
margin-bottom: rem(16);
}
&__text {
  font-size: rem(16);
  
  p {
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  
} 
}
&__design-wrap__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
&__box-design {
  width: 30%;
}
&__box-design__title {
  margin-top: rem(20);
  margin-bottom: rem(20);
}
}
.b-single-main__img-wrap {
  padding-top: 68%;
  position: relative;
  position: relative;
  overflow: hidden;

  &__label {
    position: absolute;
    top: 30px;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 0px 22px;
    background-color: $base;
    z-index: 1;
    border-radius: 20px;
  
    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url('../images/common/icon-sp.svg');
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}
.b-single-main__img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.b-single-main__box-design__img {
  width: 100%;
  height: 100%;
  margin: auto;
}

.client-box  {
  display: flex;
  align-items: center;
  font-size: rem(20);
  font-weight: bold;
  border-bottom: 1px dotted #D9D9D9;
  padding: rem(16) 0;
  &__first {
    padding-left: rem(24);
  }
  &__second {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
  }
}

.b-single-main__box__title {
  font-size: 18px;
  text-align: center;
}

.b-single-main__box-design__title {
  font-size: 16px;
  text-align: center;
}

.b-single-main__box-design {
  text-align: center;
}

.b-single-main__box-design {
  #openModal,
  #openModal2,
  #openModal3 {
    cursor: pointer;
  }
}

.client-box__second.client-box__url {
  color: $base;
  border-bottom: 1px solid $base;
}

@media screen and (max-width: 1024px) {
  .b-single-main__inner {
    padding: 50px 20px;
  }
  .b-single-main__design-wrap__inner {
    display: block;
  }
  .client-box {
    display: block;
  }
  .client-box__second {
    padding: 0;
    font-size: 18px;
  }
  .client-box__first {
    padding: 0;
    font-size: 18px;
  }
  .b-single-main__box-design {
    margin-top: 10px;
    width: 100%;
  }
.b-single-main__box-design__title {
  margin-bottom: 0;
}
}


.b-single-main__title {
  color: $base;/*文字色*/
  padding: 0.5em 0;/*上下の余白*/
  border-top: solid 3px $base;/*上線*/
  border-bottom: solid 3px $base;/*下線*/
}

.b-single-main__body {
  img {
    margin: 10px auto;
  }
}

@media screen and (max-width: 767px) {
  .b-single-main__box-design {
    #openModal,
    #openModal2,
    #openModal3 {
      display: none;
    }
  }

  .b-single-main__img-wrap {
    &__label {
      top: 10px;
      left: 10px;
      font-size: 10px;
      height: 24px;
      padding: 0px 10px;
    
      &::before {
        width: 16px;
        height: 16px;
      }
    }
  }
}



