@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-privacy-policy {
  margin-top: 48px;
  padding: 0 20px;
  &__inner {
    border: solid 6px #EAF4F4;
    max-width: 1200px;
    border-radius: 20px;
    margin: auto;
    padding: 48px;
  
  }
  &__text {
    margin-top: 18px;
    text-align: center;
    
  }
}
.b-privacy-policy__box{
  margin-top: 32px;

  &__title {
    font-size: rem(20);
    position: relative;
    padding-left: 16px;
    font-size: rem(15);
  }
  &__title::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 35px;
    background-color: $base;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__text {
    margin-top: 12px;
    font-size: rem(15);
  }


}
@media screen and (max-width:768px) {
  .b-privacy-policy__inner {
padding: 0 20px 30px;
  }
}
