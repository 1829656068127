@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

.b-company {
    padding: 0 rem(25);
}


.b-company__inner {
    max-width: rem(800);
    width: 100%;
    margin: 0 auto;
    padding: rem(80) 0 rem(300);
    position: relative;
}

.b-company__inner::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: rem(300);
    background: url(../images/common/illustration1.png) no-repeat center center / contain;
    width: rem(190);
    height: rem(251);
}

.b-company__list {
    display: flex;
    //justify-content: center;
    width: 100%;
    padding-bottom: 36px;
    border-bottom: 4px #EAF4F4 dotted;
}
.b-company__list:not(:first-child) {
    margin-top: rem(42);
}
.b-company__list__title {
    font-size: rem(20);
    font-weight: 600;
    max-width: rem(100);
    width: 100%;
}
.b-company__list__text {
    font-size: rem(20);
    font-weight: 500;
    width: calc(100% - 100px);
    text-align: right;
}

@media screen and (max-width: 767px) {
    .b-company__inner {
        max-width: rem(600);
    }

    .b-company__list {
        display: block;
        padding-bottom: 12px;
    }
    .b-company__list:not(:first-child) {
        margin-top: rem(20);
    }
    .b-company__list__title {
        font-size: rem(16);
        max-width: 100%;
    }
    .b-company__list__text {
        font-size: rem(16);
        width: 100%;
        text-align: left;
        margin-top: rem(6);
    }
}