@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

/* ------------------------------ */
/* Utility */
/* ------------------------------ */
//margin-top
.u-mt__150 {
  margin-top: 150px;
}
.u-mt__100 {
  margin-top: 100px;
}
.u-mt__70 {
  margin-top: 70px;
}
.u-mt__50 {
  margin-top: 50px;
}
.u-mt__40 {
  margin-top: 40px;
}
.u-mt__20 {
  margin-top: 20px;
}
.u-mt__10 {
  margin-top: 10px;
}
.u-mt__-100 {
  margin-top: -100px;
}
.u-mt__0 {
  margin-top: 0;
}
//margin-bottom
.u-mb__80 {
  margin-bottom: 80px;
}
.u-mb__60 {
  margin-bottom: 60px;
}
.u-mb__40 {
  margin-bottom: 40px;
}
.u-mb__30 {
  margin-bottom: 30px;
}
.u-mb__20 {
  margin-bottom: 20px;
}
//margin-left, margin-right
.u-mr__0 {
  margin-right: 0;
}
.u-ml__80 {
  margin-left: 80px;
}
.u-ml__5 {
  margin-left: 5%;
}
.u-ml__0 {
  margin-left: 0;
}
.u-m__auto {
  margin-left: auto;
  margin-right: auto;
}

//flex
.u-right {
  justify-content: flex-end;
}
.u-center {
  justify-content: center;
}
.u-row-reverse {
  flex-direction: row-reverse;
}

//font
.u-color__g {
  color: $base;
}
.u-fs__28 {
  font-size: rem(28);
}

//padding-top
.u-pt__130 {
  padding-top: 130px;
}
.u-pt__80 {
  padding-top: 80px;
}
.u-pt__50 {
  padding-top: 50px;
}
.u-pt__40 {
  padding-top: rem(40);
}
.u-pt__30 {
  padding-top: 30px;
}
.u-pt__0 {
  padding-top: 0;
}

//padding-bottom
.u-pb__100 {
  padding-bottom: 100px;
}
.u-pb__30 {
  padding-bottom: 30px;
}

//padding-left, padding-right
.u-pr__0 {
  padding-right: 0;
}

//animation
.u-later__2 {
  animation-delay: 2s;
}
.u-animate__delay-2s {
  animation-delay: 0.2s; /*この数字を指定したい遅延時間に変更*/
}
.u-animate__delay-4s {
  animation-delay: 0.4s; /*この数字を指定したい遅延時間に変更*/
}
.u-animate__delay-6s {
  animation-delay: 0.6s; /*この数字を指定したい遅延時間に変更*/
}
.u-animate__delay-8s {
  animation-delay: 0.8s; /*この数字を指定したい遅延時間に変更*/
}
.u-animate__delay-10s {
  animation-delay: 1.0s; /*この数字を指定したい遅延時間に変更*/
}
.u-animate__delay-12s {
  animation-delay: 1.2s; /*この数字を指定したい遅延時間に変更*/
}

//opacity
.u-opacity__0 {
  opacity: 0;
}
.u-opacity__1 {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  //margin-top
  //margin-left, margin-right
  .u-sp-ml__0 {
    margin-left: 0;
  }
  .u-sp-ml__auto {
    margin-left: auto;
  }

  //padding-top
  .u-sp-pt__0 {
    padding-top: 0;
  }

  //flex
  .u-sp__center {
    justify-content: center;
  }

  //top
  .u-top__-100 {
    top: -100px;
  }
  .u-top__-95 {
    top: -95px;
  }
  .u-top__-65 {
    top: -65px;
  }
  .u-top__-15 {
    top: -15px;
  }
  .u-top__10 {
    top: 10px;
  }
}

@media screen and (max-width: 767px) {
  @for $i from 0 through 10 {
    .u-sp-mt__#{$i*10} {
      margin-top: #{$i*10}px;
    }
  }
  @for $i from 0 through 10 {
    .u-sp-mb__#{$i*10} {
      margin-bottom: #{$i*10}px;
    }
  }

  .u-sp-767__mt__60 {
    margin-top: 60px;
  }
}
