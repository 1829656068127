@use "../setting" as *;
@use "../variables" as *;
@use "../function" as *;

/* Loading背景画面設定　*/
#splash {
  /*fixedで全面に固定*/
position: fixed;
width: 100%;
height: 100%;
z-index: 99999;
background: $sub;
text-align:center;
color:#fff;
}

/* Loading画像中央配置　*/
#splash_logo {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

/* Loading アイコンの大きさ設定　*/
#splash_logo img {
width:260px;
}


/*========= 画面遷移のためのCSS ===============*/

/*画面遷移アニメーション*/
.splashbg{
  display: none;
}

/*bodyにappearクラスがついたら出現*/
body.appear .splashbg{
  display: block;
  content: "";
  position:fixed;
z-index: 999;
  width: 100%;
  height: 100vh;
  top: 0;
left: 0;
  transform: scaleY(0);
  background-color: #333;/*伸びる背景色の設定*/
animation-name:PageAnime;
animation-duration:1.2s;
animation-timing-function:ease-in-out;
animation-fill-mode:forwards;
}

@keyframes PageAnime{
0% {
  transform-origin:top;
  transform:scaleY(0);
}
50% {
  transform-origin:top;
  transform:scaleY(1);
}
50.001% {
  transform-origin:bottom;
}
100% {
  transform-origin:bottom;
  transform:scaleY(0);
}
}

/*画面遷移の後現れるコンテンツ設定*/
#container{
opacity: 0;/*はじめは透過0に*/
}

/*bodyにappearクラスがついたら出現*/
body.appear #container{
animation-name:PageAnimeAppear;
animation-duration:1s;
animation-delay: 0.8s;
animation-fill-mode:forwards;
opacity: 0;
}

@keyframes PageAnimeAppear{
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}